/**
 * The following variable and function originate from the sass-mq library.
 * If you have already included it, you can eliminate the below
 * https://github.com/sass-mq/sass-mq/blob/master/_mq.scss
 */
.brod .izletbrief:before, .bus .izletbrief:before, .selfdrive .izletbrief:before, .sn-fb, .sn-yb, .sn-in, .logo, .printlink:before, .searchcall:after, .searchform input[type=checkbox]:checked:after, .harmodole span:after, .harmoplus:after, .remember .remember-opcije a:before, .menubuton, .menutitle, .contact-skype, .contact-viber, .contact-whatsup {
  display: inline-block;
  background-image: url("../images/sprite.svg");
  background-size: 32.875em 21.25em; }

.lt-ie9 .brod .izletbrief:before, .lt-ie9 .bus .izletbrief:before, .lt-ie9 .selfdrive .izletbrief:before, .lt-ie9 .sn-fb, .lt-ie9 .sn-yb, .lt-ie9 .sn-in, .lt-ie9 .logo, .lt-ie9 .printlink:before, .lt-ie9 .searchcall:after, .lt-ie9 .searchform input[type=checkbox]:checked:after, .lt-ie9 .harmodole span:after, .lt-ie9 .harmoplus:after, .lt-ie9 .remember .remember-opcije a:before, .lt-ie9 .menubuton, .lt-ie9 .menutitle, .lt-ie9 .contact-skype, .lt-ie9 .contact-viber, .lt-ie9 .contact-whatsup {
  background-image: url("../images/sprite.png"); }

.brod .izletbrief:before {
  width: 3.75em;
  height: 1.5em;
  background-position: -0.3125em -13.6875em; }
  .lt-ie9 .brod .izletbrief:before {
    width: 60px;
    height: 24px;
    background-position: -5px -219px; }

.bus .izletbrief:before {
  width: 3.75em;
  height: 2em;
  background-position: -4.6875em -15.8125em; }
  .lt-ie9 .bus .izletbrief:before {
    width: 60px;
    height: 32px;
    background-position: -75px -253px; }

.selfdrive .izletbrief:before {
  width: 3.75em;
  height: 2em;
  background-position: -0.3125em -15.8125em; }
  .lt-ie9 .selfdrive .izletbrief:before {
    width: 60px;
    height: 32px;
    background-position: -5px -253px; }

.sn-fb {
  width: 1em;
  height: 1em;
  background-position: -14.9375em -13.6875em; }
  .lt-ie9 .sn-fb {
    width: 16px;
    height: 16px;
    background-position: -239px -219px; }

.sn-yb {
  width: 1em;
  height: 1em;
  background-position: -8.4375em -13.6875em; }
  .lt-ie9 .sn-yb {
    width: 16px;
    height: 16px;
    background-position: -135px -219px; }

.sn-in {
  width: 1em;
  height: 1em;
  background-position: -6.8125em -13.6875em; }
  .lt-ie9 .sn-in {
    width: 16px;
    height: 16px;
    background-position: -109px -219px; }

/* ===========================
Base
=========================== */
html {
  line-height: 1em; }

body {
  font-family: "Open Sans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 1em;
  line-height: 1.3em;
  color: #102c69;
  height: 100%; }

li {
  list-style-type: square; }

b,
strong {
  font-weight: 700; }

a {
  color: #009ee1;
  text-decoration: none;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  a:hover {
    color: #6d6e71; }

::-moz-selection {
  background: #f00;
  color: #fff;
  text-shadow: none; }

::selection {
  background: #f00;
  color: #fff;
  text-shadow: none; }

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0; }

/* ==|====================
Layout/Header
======================= */
.zaglavlje {
  background-color: #102c69;
  background-image: none;
  color: #102c69; }

.fancy {
  font-family: 'Kaushan Script', cursive; }

h2.fancy {
  font-size: 3em;
  color: #009ee1; }

h1 {
  color: #009ee1;
  font-size: 2em; }

h1.singletitle {
  text-align: center;
  font-size: 3.5em;
  color: #fff; }

h2 {
  line-height: 1.1em; }

.logo {
  font-size: 0.5em;
  width: 20vw;
  text-indent: -200%;
  overflow: hidden;
  width: 32.25em;
  height: 4.875em;
  background-position: -0.3125em -8.1875em; }
  @media screen and (min-width: 769px) {
    .logo {
      font-size: 7px; } }
  @media screen and (min-width: 960px) {
    .logo {
      font-size: 0.6em; } }
  @media (min-width: 1200px) {
    .logo {
      font-size: 0.71em; } }
  .lt-ie9 .logo {
    width: 516px;
    height: 78px;
    background-position: -5px -131px; }

.footer {
  padding: 2em;
  font-size: 1em;
  background-color: #ffca00;
  text-align: center; }
  .footer .printcontacts {
    margin-top: 2em;
    border-top: 1px solid #333;
    display: none; }

.copy {
  font-size: 0.6em; }

.userbar {
  font-size: 0.8em;
  background-color: #ffca00;
  color: #fff;
  margin-bottom: 8px; }
  .userbar .menujezik a {
    padding: 0.2em 0.3em;
    color: #102c69; }
    .userbar .menujezik a.active {
      color: #63b340; }
    .userbar .menujezik a:hover, .userbar .menujezik a:hover.active {
      color: #63b340; }

.userbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-right: 50px; }
  .userbar .space {
    -webkit-box-flex: 10;
        -ms-flex: 10 auto;
            flex: 10 auto; }
  .userbar .menujezik {
    display: none; }
    @media screen and (min-width: 769px) {
      .userbar .menujezik {
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin: .2em .4em;
        border-right: 1px dotted #102c69; }
        .userbar .menujezik ul {
          padding: 0; } }
    @media (min-width: 1200px) {
      .userbar .menujezik {
        margin: .2em 1em;
        border-right: none; } }
  .userbar .menukontakti {
    display: none; }
    @media screen and (min-width: 769px) {
      .userbar .menukontakti {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        margin: .2em .4em;
        color: #009ee1;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        border-right: 1px dotted #102c69; }
        .userbar .menukontakti a {
          color: #102c69;
          padding: 0.2em 0.3em; } }
    @media (min-width: 1200px) {
      .userbar .menukontakti {
        margin: .2em 1em;
        border-right: none; } }
  .userbar .userlogin {
    display: none; }
    @media screen and (min-width: 769px) {
      .userbar .userlogin {
        display: block;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto;
        margin: .2em .8em 0.2em 0.4em;
        color: #102c69;
        background-color: #fff; }
        .userbar .userlogin:hover {
          background-color: #cbcac9; } }
  .userbar .currentuser {
    display: block;
    background-color: transparent;
    margin: .2em auto 0.2em auto;
    padding: 0.2em 0.3em;
    display: block;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    color: #102c69; }
    .userbar .currentuser:hover {
      background-color: transparent; }

.promoizleti {
  margin: 2em auto;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.promoizlet {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100vw;
          flex: 1 1 100vw;
  margin-bottom: 20px;
  max-width: screen 769px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 769px) {
    .promoizlet {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 45%;
              flex: 1 1 45%;
      margin: 10px;
      max-width: 460px;
      min-height: 450px; } }
  @media screen and (min-width: 960px) {
    .promoizlet {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 30%;
              flex: 1 1 30%;
      max-width: 380px;
      min-height: 470px; } }
  @media (min-width: 1200px) {
    .promoizlet {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 22%;
              flex: 1 1 22%;
      max-width: 450px; } }
  .promoizlet header {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    background-color: #eee;
    padding: 1em 0.5em 0.2em;
    box-sizing: border-box;
    width: 100%;
    height: auto;
    display: block; }
    .promoizlet header h2,
    .promoizlet header h3 {
      margin: 0;
      padding: 0; }
    .promoizlet header h2 {
      line-height: 1.1em; }
    .promoizlet header h3 {
      color: #009ee1;
      font-weight: normal;
      font-size: 1em;
      margin-top: 0.3em; }
    .promoizlet header a h2 {
      color: #102c69; }
    .promoizlet header a h3 {
      color: #009ee1; }
    .promoizlet header:hover {
      background-color: #ffca00; }
      .promoizlet header:hover h2,
      .promoizlet header:hover h3 {
        color: #102c69; }
  .promoizlet .bookinglist {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }

.promoizletblok {
  min-height: 220px;
  background-size: cover;
  background-position: 50% 50%;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  @media screen and (min-width: 769px) {
    .promoizletblok {
      min-height: 200px; } }

.izletbrief {
  background-color: rgba(255, 255, 255, 0.2);
  text-align: center;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -ms-flex-item-align: auto;
      -ms-grid-row-align: auto;
      align-self: auto;
  padding: 20px 20px;
  color: #fff; }
  .izletbrief .cijenaod {
    display: block;
    margin: 0;
    line-height: 1.1em;
    font-size: 0.45em;
    font-weight: normal;
    text-transform: uppercase;
    text-align: left; }
  .izletbrief .cijena {
    font-size: 1.6em;
    line-height: 1em;
    padding: 10px 0 0;
    margin: 0;
    font-weight: bold;
    border-top-width: 2px;
    border-top-style: dotted; }
    .izletbrief .cijena:after {
      content: " KN";
      font-size: 0.5em;
      font-weight: normal; }
  .izletbrief .cijenafrom:before {
    content: "FROM";
    display: block;
    margin: 0;
    line-height: 1.1em;
    font-size: 0.45em;
    font-weight: normal;
    text-align: left; }
  .izletbrief .cijenaodhr:before {
    content: "OD";
    display: block;
    margin: 0;
    line-height: 1.1em;
    font-size: 0.45em;
    font-weight: normal;
    text-align: left; }
  .izletbrief .cijenaeur {
    font-size: 1.6em;
    line-height: 1em;
    padding: 0 0 10px 0;
    margin: 0;
    font-weight: bold; }
    .izletbrief .cijenaeur:after {
      content: " EUR";
      font-size: 0.5em;
      font-weight: normal; }
  .izletbrief .trajanje {
    position: relative;
    font-size: 2em;
    line-height: 0.8em;
    padding: 0;
    margin: 0;
    font-weight: bold; }
    .izletbrief .trajanje:before {
      content: "\2248";
      font-size: 0.5em;
      font-weight: normal; }
    .izletbrief .trajanje:after {
      content: "h";
      font-size: 0.5em;
      font-weight: normal; }
  .izletbrief:before {
    content: '';
    opacity: 0.5;
    margin: 0;
    font-size: 1.2em;
    margin-bottom: -0.8em; }

a.booknow {
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  -ms-flex-item-align: start;
      align-self: flex-start;
  font-size: 1em;
  margin: 10px 0;
  border: 1px solid #fff;
  padding: 8px 20px;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.18s linear;
  transition: all 0.18s linear;
  background-color: #52883a;
  background: -webkit-linear-gradient(84deg, #102c69 5%, #009ee1 35%, #63b340 65%, #52883a 95%);
  background: linear-gradient(6deg, #102c69 5%, #009ee1 35%, #63b340 65%, #52883a 95%);
  background-size: 320%;
  background-position: 1% 1%; }
  @media screen and (min-width: 769px) {
    a.booknow {
      margin: 10px; } }
  a.booknow:hover {
    background-color: #009ee1;
    background-position: 99% 99%; }

.printlink {
  margin-top: 1em;
  margin-bottom: 1em;
  font-size: 0.8em;
  text-transform: uppercase;
  color: #333;
  background-color: #f0f0f0;
  padding: 0.5em 1.4em;
  border: 1px solid #333;
  border-radius: 2em;
  display: inline-block;
  line-height: 2em; }
  .printlink:hover {
    color: #009ee1;
    background-color: #fff; }
  .printlink:before {
    content: '';
    font-size: 0.8em;
    width: 2em;
    height: 2em;
    background-position: -16.9375em -15.8125em;
    margin-right: 5px;
    position: relative;
    top: 0.55em; }
    .lt-ie9 .printlink:before {
      width: 32px;
      height: 32px;
      background-position: -271px -253px; }

.bojazuta .izletbrief {
  color: #102c69;
  background-color: #ffca00;
  background-color: rgba(255, 202, 0, 0.7);
  background: -webkit-linear-gradient(top, #ffca00, rgba(255, 202, 0, 0));
  background: linear-gradient(to bottom, #ffca00, rgba(255, 202, 0, 0)); }

.bojasiva .izletbrief {
  color: #ffca00;
  background-color: #6d6e71;
  background-color: rgba(109, 110, 113, 0.7);
  background: -webkit-linear-gradient(top, #6d6e71, rgba(109, 110, 113, 0));
  background: linear-gradient(to bottom, #6d6e71, rgba(109, 110, 113, 0)); }

.bojazelena .izletbrief {
  color: #fff;
  background-color: #63b340;
  background-color: rgba(99, 179, 64, 0.5);
  background: -webkit-linear-gradient(top, #63b340, rgba(99, 179, 64, 0));
  background: linear-gradient(to bottom, #63b340, rgba(99, 179, 64, 0)); }

.bojaplava .izletbrief {
  color: #fff;
  background-color: #009ee1;
  background-color: rgba(0, 158, 225, 0.8);
  background: -webkit-linear-gradient(top, #009ee1, rgba(0, 158, 225, 0));
  background: linear-gradient(to bottom, #009ee1, rgba(0, 158, 225, 0)); }

.bojatplava .izletbrief {
  color: #fff;
  background-color: #102c69;
  background-color: rgba(16, 44, 105, 0.8);
  background: -webkit-linear-gradient(top, #102c69, rgba(0, 158, 225, 0));
  background: linear-gradient(to bottom, #102c69, rgba(0, 158, 225, 0)); }

.bojamplava .izletbrief {
  color: #fff;
  background-color: #0072bc;
  background-color: rgba(0, 114, 188, 0.8);
  background: -webkit-linear-gradient(top, #0072bc, rgba(0, 114, 188, 0));
  background: linear-gradient(to bottom, #0072bc, rgba(0, 114, 188, 0)); }

.promo {
  background-color: #fff; }

#promo-zaglavlje .owl-stage-outer {
  width: 100%; }

#promo-zaglavlje .owl-item {
  display: block;
  width: 100%;
  height: auto; }

.promoslide {
  display: block;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 55vh;
  text-align: center;
  background-size: cover;
  background-position: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (orientation: portrait) {
    .promoslide {
      height: 50vh; } }
  @media (orientation: landscape) {
    .promoslide {
      height: 75vh; } }

.headerpromo {
  width: 100%;
  min-height: 50vh;
  background-color: #009ee1; }

.headerpromoinside {
  margin: 0 auto;
  width: 100%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  text-align: center;
  color: #fff; }
  .headerpromoinside h1 {
    width: 80%;
    max-width: 90vw;
    line-height: 1em;
    color: #fff;
    margin: 0.2em auto;
    font-size: 3em;
    font-size: calc(1em + 3vmin);
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
  .headerpromoinside h2 {
    width: 80%;
    max-width: 90vw;
    line-height: 1em;
    color: #ffca00;
    margin: 0.1em auto;
    font-size: 2em;
    font-size: calc(0.75em + 2.5vmin);
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.2); }
  .headerpromoinside h2.fancy {
    width: 80%;
    max-width: 90vw;
    font-size: 2em;
    font-size: calc(0.75em + 1.5vmin);
    text-align: center; }
    @media screen and (min-width: 960px) {
      .headerpromoinside h2.fancy {
        padding-left: 150px; } }

.searchbar {
  position: relative;
  background-color: #009ee1;
  overflow: hidden; }

.searchcall {
  text-align: center;
  font-size: 1.2em;
  font-weight: 300;
  margin: 0.5em auto;
  padding: 5px;
  color: #99d8f3;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .searchcall:after {
    content: '';
    margin-left: 15px;
    font-size: 0.5em;
    width: 2em;
    height: 2em;
    background-position: -22.1875em -15.8125em;
    margin-bottom: -3px; }
    .lt-ie9 .searchcall:after {
      width: 32px;
      height: 32px;
      background-position: -355px -253px; }
  .searchcall:hover {
    cursor: pointer;
    color: #e6f5fc; }

.searchizlet {
  max-height: 0;
  top: 0;
  -webkit-transform: scaleY(0.5);
          transform: scaleY(0.5);
  -webkit-transform: scaleX(0);
          transform: scaleX(0);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  box-sizing: border-box;
  padding: 0em;
  overflow: hidden;
  line-height: 1.2em;
  color: #fff; }

.visiblesearch {
  padding: 2em 1em;
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  -webkit-transform: scaleX(1);
          transform: scaleX(1);
  max-height: 700px; }
  @media screen and (min-width: 960px) {
    .visiblesearch {
      max-height: 200px; } }

.searchform {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 960px) {
    .searchform {
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }

.searchform li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  list-style-image: none;
  width: 100%;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  margin-bottom: 10px; }
  @media screen and (min-width: 960px) {
    .searchform li {
      width: auto;
      -ms-flex-item-align: stretch;
          -ms-grid-row-align: stretch;
          align-self: stretch;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      margin: 0 10px;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 auto;
              flex: 0 1 auto; } }

.searchform .libuton {
  -ms-flex-item-align: end;
      align-self: flex-end; }

.searchform li label,
.searchform li p {
  width: 50%;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin-bottom: 0px;
  margin-right: 10px;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  text-align: right;
  font-weight: normal;
  color: #fff;
  font-size: 0.9em;
  text-transform: uppercase; }
  @media screen and (min-width: 960px) {
    .searchform li label,
    .searchform li p {
      -ms-flex-item-align: start;
          align-self: flex-start;
      width: auto;
      margin-bottom: 10px; } }

.searchform input,
.searchform button,
.searchform select {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  text-align: left;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }
  @media screen and (min-width: 960px) {
    .searchform input,
    .searchform button,
    .searchform select {
      -ms-flex-item-align: start;
          align-self: flex-start; } }

.searchform select {
  padding-right: 30px; }

.searchform li.ds {
  width: auto;
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  margin: 0 10px 30px 10px; }
  @media screen and (min-width: 960px) {
    .searchform li.ds {
      margin: 0 10px; } }
  .searchform li.ds label {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    width: auto;
    margin-bottom: 10px; }
    @media screen and (min-width: 960px) {
      .searchform li.ds label {
        -ms-flex-item-align: start;
            align-self: flex-start; } }
  .searchform li.ds select {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
    @media screen and (min-width: 960px) {
      .searchform li.ds select {
        -ms-flex-item-align: start;
            align-self: flex-start; } }

.ui-datepicker {
  background-color: #ffca00;
  padding: 10px; }
  .ui-datepicker .ui-datepicker-prev,
  .ui-datepicker .ui-datepicker-next {
    display: none; }

#searchbuton {
  padding: 7px 12px;
  border: none;
  background: #63b340;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: 2px; }
  #searchbuton:hover {
    cursor: pointer;
    background: #52883a; }

.searchform select {
  background: transparent;
  border: 1px dotted #fff;
  background-color: #b3e2f6;
  font-size: 1em;
  color: #102c69;
  padding: 5px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  /* If you add too much padding here, the options won't show in IE */ }

.searchform input {
  background: transparent;
  border: 1px dotted #fff;
  background-color: #b3e2f6;
  font-size: 1em;
  color: #102c69;
  padding: 5px;
  /* If you add too much padding here, the options won't show in IE */ }

.searchform input[type=checkbox] {
  -webkit-appearance: none;
  background-color: #b3e2f6;
  border: 1px dotted #fff;
  box-shadow: none;
  margin: 2px;
  padding: 12px;
  border-radius: 2px;
  display: inline-block;
  position: relative; }
  .searchform input[type=checkbox]:active,
  .searchform input[type=checkbox] :checked:active {
    box-shadow: none; }
  .searchform input[type=checkbox]:checked {
    background-color: #63b340;
    box-shadow: none;
    color: #fff;
    padding: 14px;
    margin: 0; }
  .searchform input[type=checkbox]:checked:after {
    position: absolute;
    top: -1px;
    left: -1px;
    content: '';
    font-size: 2em;
    width: 1em;
    height: 1em;
    background-position: -10.0625em -13.6875em; }
    .lt-ie9 .searchform input[type=checkbox]:checked:after {
      width: 16px;
      height: 16px;
      background-position: -161px -219px; }

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin-top: 10px;
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-nav [class*='owl-'] {
    color: #FFF;
    font-size: 14px;
    margin: 5px;
    padding: 4px 7px;
    background: #b3e2f6;
    display: inline-block;
    cursor: pointer;
    border-radius: 1px; }
    .owl-theme .owl-nav [class*='owl-']:hover {
      background: #009ee1;
      color: #FFF;
      text-decoration: none; }
  .owl-theme .owl-nav .disabled {
    opacity: 0.5;
    cursor: default; }

.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 10px; }

.owl-theme .owl-dots {
  text-align: center;
  -webkit-tap-highlight-color: transparent; }
  .owl-theme .owl-dots .owl-dot {
    display: inline-block;
    zoom: 1;
    *display: inline; }
    .owl-theme .owl-dots .owl-dot span {
      width: 20px;
      height: 10px;
      margin: 1px 1px;
      background: #b3e2f6;
      display: block;
      -webkit-backface-visibility: visible;
      -webkit-transition: opacity 200ms ease;
      transition: opacity 200ms ease;
      border-radius: 1px; }
    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
      background: #009ee1; }

.owl-theme .owl-nav {
  margin-top: 0; }

.headerpromo .promolink {
  display: none; }
  @media screen and (min-width: 960px) {
    .headerpromo .promolink {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }

.promolink {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  overflow-x: hidden;
  margin: 2em auto; }
  .promolink a,
  .promolink p {
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: 2px;
    font-size: 1em;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    padding: 19px 20px;
    border: 1px solid white;
    color: #fff;
    background-color: #009ee1;
    background-color: rgba(0, 158, 225, 0.7);
    -webkit-transform: all 0.2s linear;
            transform: all 0.2s linear; }
  .promolink p {
    padding: 14px 20px;
    margin: 0 10px;
    text-align: center; }
  .promolink a:not(:last-child) {
    margin-right: 10px;
    margin-left: 10px; }
  .promolink a:hover {
    background-color: #63b340;
    background-color: #63b340;
    color: #fff; }
  .promolink select {
    margin: 0 15px;
    max-width: 85%;
    box-sizing: border-box;
    background: transparent;
    border: none;
    background-color: #e6f5fc;
    font-size: 1em;
    color: #99d8f3;
    padding: 5px;
    -webkit-appearance: none;
    -webkit-border-radius: 0px;
    background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
    background-position: 100% 50%;
    background-repeat: no-repeat;
    /* If you add too much padding here, the options won't show in IE */ }
    .promolink select:focus {
      color: #102c69; }

.cta {
  text-align: center;
  display: inline-block;
  margin: 1em auto;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: 2px;
  font-size: 1em;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  padding: 14px 20px;
  border: 1px solid white;
  color: #fff;
  background-color: #009ee1;
  background-color: rgba(0, 158, 225, 0.7);
  -webkit-transform: all 0.2s linear;
          transform: all 0.2s linear; }
  .cta:first-child {
    margin: 0 auto 1em; }
  .cta:hover {
    background-color: #63b340;
    background-color: #63b340;
    color: #fff; }
  .cta span {
    display: block;
    font-size: 0.7em; }

.menudesktop {
  display: none;
  margin: 2em auto; }
  @media screen and (min-width: 960px) {
    .menudesktop {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; } }
  .menudesktop a {
    font-size: 1em;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 auto;
            flex: 0 1 auto;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
    padding: 20px;
    color: #009ee1;
    -webkit-transform: all 0.2s linear;
            transform: all 0.2s linear; }
  .menudesktop a:not(:last-child) {
    border-right: 1px dotted #009ee1; }
  .menudesktop a:hover {
    background-color: #009ee1;
    color: #fff; }

.whyus {
  background: #eee;
  text-align: center;
  padding: 1.8em 1em; }
  .whyus h3 {
    font-size: 1.7em;
    color: #009ee1;
    margin: 0 0 0.5em;
    padding: 0; }
  .whyus h3.harmoplus {
    font-size: 1.5em;
    font-size: calc(1em + 0.5vmin);
    color: #009ee1;
    text-transform: uppercase;
    margin: 0;
    padding: 0; }
  .whyus h4 {
    font-size: 1em;
    color: #6d6e71;
    margin: 0;
    padding: 0 0.5em 0.5em; }
  .whyus .nika h4 {
    font-size: 1em;
    font-size: calc(1em + 0.5vmin);
    color: #6d6e71;
    margin: 0;
    padding: 0 0.5em 0.5em; }
  .whyus ul {
    max-width: 1200px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin: 0 auto;
    padding: 0.5em;
    list-style: none; }
    .whyus ul li {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 90%;
              flex: 1 1 90%;
      list-style-type: none; }
      @media screen and (min-width: 960px) {
        .whyus ul li {
          -webkit-box-flex: 1;
              -ms-flex: 1 1 12%;
                  flex: 1 1 12%; } }
    .whyus ul li:not(:last-child) {
      padding-bottom: 1em;
      border-bottom: 1px solid #ccc;
      margin-bottom: 0.5em; }
      @media screen and (min-width: 960px) {
        .whyus ul li:not(:last-child) {
          padding: 0;
          border: none;
          margin: 0;
          padding-right: 1em;
          border-right: 1px solid #aaa;
          margin-right: 1em; } }

.triper {
  margin: 1em auto;
  text-align: center;
  max-width: 960px; }

#CDSWIDSSP {
  width: 100% !important; }

.widSSPData {
  border: none !important; }

.promoporuka {
  font-weight: normal; }

.bookstatic {
  z-index: 900;
  width: 100%;
  width: 100vw;
  position: fixed;
  bottom: 0;
  display: block;
  text-align: center;
  padding: 10px;
  background-color: #63b340;
  color: #fff;
  cursor: pointer; }
  .bookstatic:hover {
    background-color: #102c69;
    color: #fff; }

.single .content {
  margin: 0 auto; }
  .single .content img {
    max-width: 100%;
    height: auto; }

.content-stranica {
  background-color: #fff;
  width: 90%;
  max-width: 1200px;
  margin: 2em auto; }

.bookinfo {
  text-align: center;
  margin: 0 auto;
  background-color: #efefef; }

.mainbookinfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap; }

.bookingdates {
  box-sizing: border-box;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 479px;
          flex: 1 1 479px;
  text-align: center;
  margin: 0 auto;
  padding: 0.5em 1em;
  background-color: #cbcac9; }
  .bookingdates h3 {
    font-size: 2em;
    font-size: calc(0.75em + 1.65vmin);
    color: #102c69; }

.basicdata {
  box-sizing: border-box;
  -ms-flex-item-align: stretch;
      -ms-grid-row-align: stretch;
      align-self: stretch;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 479px;
          flex: 1 1 479px;
  background-color: #009ee1;
  text-align: center;
  padding: 0.5em 10px; }
  .basicdata h3 {
    font-size: 2em;
    font-size: calc(0.75em + 1.65vmin);
    color: #fff; }
  .basicdata table {
    margin: 0 auto 2em;
    max-width: 900px;
    line-height: 1em; }
    @media screen and (min-width: 960px) {
      .basicdata table {
        line-height: 1.2em; } }
    .basicdata table td {
      text-align: left;
      padding: 0.3em 0.8em 0.3em 0;
      color: #fff; }
      @media screen and (min-width: 960px) {
        .basicdata table td {
          padding: 1em 0.8em 1em 0; } }
    .basicdata table td.c1 {
      text-transform: uppercase;
      font-size: 0.8em; }
    .basicdata table td.c2 {
      border-top: 1px dotted #fff;
      font-size: 0.9em; }
    .basicdata table table {
      width: 100%; }
      .basicdata table table td {
        text-align: left;
        padding: 0.1em 0em 0.1em 0;
        color: #fff; }
        @media screen and (min-width: 960px) {
          .basicdata table table td {
            padding: 0.2em 0em 0.2em 0; } }
      .basicdata table table td.c3 {
        vertical-align: top; }
      .basicdata table table td.c4 {
        width: 25%;
        vertical-align: top; }

.content h2 span,
.content h3 span {
  color: #102c69; }

.bookinglist {
  padding: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: center;
          flex-direction: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .bookinglist li {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;
    background-color: #63b340;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 40%;
            flex: 1 1 40%;
    margin: 5px;
    border: 2px solid #fff; }
    .bookinglist li:hover {
      background-color: #009ee1; }
    .bookinglist li a {
      display: block;
      color: #fff;
      letter-spacing: .09em;
      text-transform: uppercase;
      padding: 0.5em 0.5em 1.5em; }
      .bookinglist li a span {
        display: block;
        background-color: #102c69;
        padding: 4px;
        font-size: 0.8em;
        margin-bottom: 1em; }
    @media screen and (min-width: 769px) {
      .bookinglist li {
        -webkit-box-flex: 0;
            -ms-flex: 0 1 20%;
                flex: 0 1 20%; } }

.promoizlet .bookinglist {
  background-color: #eee;
  padding: 10px 10px;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: center;
          flex-direction: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .promoizlet .bookinglist li {
    font-size: 0.7em;
    text-align: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;
    background-color: #63b340;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 20%;
            flex: 1 1 20%;
    margin: 2px;
    border: none; }
    .promoizlet .bookinglist li:hover {
      background-color: #009ee1; }
    .promoizlet .bookinglist li a {
      display: block;
      color: #fff;
      letter-spacing: .09em;
      text-transform: uppercase;
      padding: 0 0 1em 0; }
      .promoizlet .bookinglist li a span {
        font-size: 0.8em;
        display: block;
        background-color: #102c69;
        padding: 2px 3px;
        margin-bottom: 1em; }
    @media screen and (min-width: 769px) {
      .promoizlet .bookinglist li {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 20%;
                flex: 1 1 20%; } }

.nota {
  font-size: 1em;
  color: #009ee1;
  width: 90%;
  max-width: 600px;
  border-bottom: 1px dotted #009ee1;
  text-align: center;
  margin: 0 auto 1em;
  padding: 1em 0.5em; }
  @media screen and (min-width: 769px) {
    .nota {
      border-top: 1px dotted #009ee1;
      margin: 2em auto;
      padding: 2em 0.5em; } }

.bookinglistmore {
  font-size: 0.9em;
  padding: 0 10px;
  margin-top: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: center;
          flex-direction: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: #fff; }
  .bookinglistmore:before {
    content: '';
    width: 100%;
    height: 20px; }
  .bookinglistmore li {
    text-align: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    text-align: center;
    background-color: #63b340;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 auto;
            flex: 1 1 auto;
    margin: 10px;
    border: 2px solid #fff; }
    .bookinglistmore li a {
      display: block;
      color: #fff;
      padding: 0.5em 0.5em; }
      .bookinglistmore li a:hover {
        background-color: #009ee1; }
    @media screen and (min-width: 769px) {
      .bookinglistmore li {
        -webkit-box-flex: 1;
            -ms-flex: 1 1 auto;
                flex: 1 1 auto;
        max-width: 200px; } }
  .bookinglistmore.isDown {
    margin-top: 0; }

.izlet-video-wrapper {
  max-width: 960px;
  margin: 1em auto; }
  .izlet-video-wrapper p {
    font-size: 0.7em;
    margin: 5px 10px;
    padding: 0;
    color: #cbcac9; }

.izlet-video {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden; }

.izlet-video iframe,
.izlet-video object,
.izlet-video embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.harmodole {
  font-size: 0.85em;
  background-color: #cbcac9;
  display: inline-block;
  padding: 5px 20px 10px;
  text-align: center;
  -webkit-transform: all 0.2s ease-in-out;
          transform: all 0.2s ease-in-out;
  margin-bottom: 0; }
  .harmodole span:after {
    content: '';
    width: 1.5em;
    height: 1.5em;
    background-position: -4.6875em -13.6875em;
    font-size: 0.8em;
    margin: 0 auto;
    display: block; }
    .lt-ie9 .harmodole span:after {
      width: 24px;
      height: 24px;
      background-position: -75px -219px; }
  .harmodole:hover {
    cursor: pointer;
    background-color: #fff; }
  .harmodole.isOpen {
    background-color: #fff; }
    .harmodole.isOpen:hover {
      color: #6d6e71; }
    .harmodole.isOpen span:after {
      -webkit-transform: rotate(180deg);
              transform: rotate(180deg); }

.nika .harmodole {
  background-color: #fff; }
  .nika .harmodole:hover {
    background-color: #ffca00; }

.harmo {
  position: relative;
  text-align: center; }

.nika {
  max-height: 0;
  overflow: hidden;
  margin: 0; }

.isDown {
  max-height: 100%;
  margin-top: 1em; }
  @media screen and (min-width: 960px) {
    .isDown {
      max-height: 15000px; } }

.izletintro,
.highlights {
  max-width: 1200px;
  padding: 0 1em;
  margin: 2em auto; }

.izletintro h2 {
  text-align: center; }

.highlights h2 {
  text-align: center;
  color: #009ee1; }

.highlights li {
  margin-bottom: 0.8em; }

.harmoplus {
  font-size: 1.5em;
  font-size: calc(1em + 0.5vmin); }
  .harmoplus:hover {
    cursor: pointer; }
  .harmoplus:after {
    margin: 10px auto 0;
    font-size: 1em;
    display: block;
    content: '';
    width: 2.25em;
    height: 2.25em;
    background-position: -9.3125em -18.4375em; }
    .lt-ie9 .harmoplus:after {
      width: 36px;
      height: 36px;
      background-position: -149px -295px; }
  .harmoplus.isOpen:after {
    background-position: -12.1875em -18.4375em; }
    .lt-ie9 .harmoplus.isOpen:after {
      background-position: -195px -295px; }

h3.harmoplus {
  font-size: 1.5em;
  font-size: calc(1em + 0.5vmin); }

h1.harmoplus {
  font-size: 2em;
  font-size: calc(1.5em + 0.5vmin); }
  h1.harmoplus:after {
    font-size: 0.75em; }

.includedinprice,
.izletdetail,
.sadrzaj {
  padding: 1em 1em;
  background-color: #efefef; }
  .includedinprice .nika,
  .izletdetail .nika,
  .sadrzaj .nika {
    margin: 0 auto;
    max-width: 1200px; }

.sadrzajuski {
  padding: 1em 2em 2em;
  margin: 0 auto;
  max-width: 1200px;
  background-color: #fff; }

.gal {
  margin-bottom: 10px;
  width: 100%; }

.wheretobuy .content {
  background: #fff;
  padding-top: 3em;
  width: 100%;
  margin: 0px auto; }
  @media screen and (min-width: 769px) {
    .wheretobuy .content {
      width: 90%;
      max-width: 1200px; } }
  .wheretobuy .content .header {
    box-sizing: border-box;
    padding: 20px 20px 40px; }
    .wheretobuy .content .header h1 {
      font-size: 2em;
      font-size: calc(0.75em + 3vmin);
      font-weight: normal; }

.where-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }

.where-general {
  box-sizing: border-box;
  padding: 0 20px 20px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 360px;
          flex: 1 1 360px; }

.where-local {
  box-sizing: border-box;
  padding: 20px 20px 40px;
  background: #e6f5fc;
  background: -webkit-linear-gradient(top, #fff 1%, #e6f5fc 60%, #ccecf9 99%);
  background: linear-gradient(180deg, #fff 1%, #e6f5fc 60%, #ccecf9 99%);
  -webkit-box-flex: 1;
      -ms-flex: 1 1 120px;
          flex: 1 1 120px; }
  .where-local h3 {
    margin: 0;
    font-weight: normal;
    text-transform: uppercase; }
  .where-local .harmo {
    text-align: left;
    padding-top: 8px; }
    .where-local .harmo:not(:first-of-type) {
      margin-top: 10px;
      border-top: 1px dotted #009ee1; }
  .where-local .harmoplus:after {
    opacity: 0.4;
    font-size: 0.6em;
    display: inline-block;
    position: absolute;
    top: 0.5em;
    right: 20px;
    margin: 0;
    margin-left: 10px; }
  .where-local .harmoplus:hover:after {
    opacity: 1; }
  .where-local div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .where-local div p {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 200px;
              flex: 1 1 200px; }

.raster {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  list-style-type: none;
  list-style-image: none;
  padding: 0; }
  .raster li {
    list-style-type: none;
    list-style-image: none;
    margin: 10px;
    box-sizing: border-box;
    padding: 20px;
    border: 1px dotted #009ee1;
    -webkit-box-flex: 1;
        -ms-flex: 1 1 280px;
            flex: 1 1 280px; }

.djelatnici strong {
  color: #009ee1;
  font-size: 1.3em;
  font-weight: normal; }

.groupdeals {
  background-color: #f0f0f0;
  background: -webkit-linear-gradient(bottom, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%);
  background: linear-gradient(0deg, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%); }
  .groupdeals .content {
    width: 100%;
    margin: 20px auto; }
    @media screen and (min-width: 769px) {
      .groupdeals .content {
        width: 90%;
        max-width: 1200px; } }
  .groupdeals .content h2 {
    font-weight: normal;
    border-bottom: 1px dotted #009ee1; }
  .groupdeals .blogarticle h3 {
    margin: 10px 0 0 0;
    font-weight: normal;
    font-size: 1.4em;
    color: #009ee1; }
    @media screen and (min-width: 769px) {
      .groupdeals .blogarticle h3 {
        text-align: left; } }
  @media screen and (min-width: 769px) {
    .groupdeals .blogarticle h2 {
      text-align: left; } }
  .groupdeals .blogarticle p {
    color: #6d6e71; }
    @media screen and (min-width: 769px) {
      .groupdeals .blogarticle p {
        text-align: left; } }

.content-intro {
  max-width: 90%;
  margin: 0 auto; }

.contactus {
  display: inline-block;
  margin: 1em auto;
  padding: 8px 15px;
  background-color: #0072bc;
  color: #fff;
  text-decoration: none;
  text-transform: uppercase; }
  .contactus:hover {
    background-color: #102c69;
    color: #ffca00; }

.odvoji {
  margin: 150px auto 3.5em; }

.akcija {
  box-sizing: border-box;
  margin: -30px auto 15px;
  max-width: 90%;
  display: block;
  background-color: #fff;
  border-top: 16px solid #ffca00;
  border-bottom: 16px solid #102c69;
  border-left-color: #102c69;
  border-right-color: #102c69;
  padding: 15px;
  text-decoration: none;
  text-align: center;
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear; }
  @media screen and (min-width: 769px) {
    .akcija {
      margin: -80px auto 20px;
      padding: 30px 20px;
      max-width: 600px; } }
  .akcija:hover {
    border-color: #009ee1; }
  .akcija a, .akcija a:link, .akcija a:visited {
    color: #102c69; }
    .akcija a:hover h2, .akcija a:link:hover h2, .akcija a:visited:hover h2 {
      color: #009ee1; }
  .akcija h3 {
    font-size: 1.1em;
    text-align: center;
    margin: 0 auto;
    line-height: 1.2em;
    font-weight: 400; }
  .akcija h2 {
    font-weight: 800;
    font-size: 1.1em;
    margin: 0 auto;
    line-height: 1.2em;
    text-transform: uppercase; }
  .akcija p {
    margin: 5px auto; }

.accomodation-partners {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch; }
  @media screen and (min-width: 769px) {
    .accomodation-partners {
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }

.accomodation-partner {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 98%;
          flex: 1 1 98%;
  max-width: 600px;
  margin-bottom: 20px;
  border-bottom: 5px solid #009ee1;
  background-color: #eee; }
  .accomodation-partner .acc-partner-opis {
    padding-top: 10px;
    padding-bottom: 10px;
    padding: 10px; }
  .accomodation-partner h2, .accomodation-partner h3, .accomodation-partner p {
    margin: 0.5em 0; }
  .accomodation-partner h2 {
    font-size: 1.4em;
    font-size: 1em;
    text-transform: uppercase; }
  .accomodation-partner h3 {
    font-size: 1em;
    color: #009ee1;
    font-weight: normal; }
  .accomodation-partner a {
    display: inline-block;
    margin-top: 10px;
    padding: 10px;
    background-color: #009ee1;
    border: 2px solid #fff;
    position: absolute;
    right: 10px;
    top: 0; }
  .accomodation-partner a, .accomodation-partner a:link, .accomodation-partner a:visited, .accomodation-partner a:active {
    color: #fff; }
  .accomodation-partner a:hover {
    background-color: #63b340; }
  .accomodation-partner img {
    max-width: 100%;
    height: auto; }
  @media screen and (min-width: 769px) {
    .accomodation-partner {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 48%;
              flex: 0 1 48%; } }
  @media screen and (min-width: 960px) {
    .accomodation-partner {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 32%;
              flex: 0 1 32%; } }
  @media (min-width: 1200px) {
    .accomodation-partner {
      -webkit-box-flex: 0;
          -ms-flex: 0 1 23%;
              flex: 0 1 23%; } }

.blogsvi {
  background-color: #f0f0f0;
  background: -webkit-linear-gradient(bottom, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%);
  background: linear-gradient(0deg, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%); }
  .blogsvi .content {
    width: 100%;
    margin: 20px auto; }
    @media screen and (min-width: 769px) {
      .blogsvi .content {
        width: 90%;
        max-width: 1200px; } }

.blog {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .blog article {
    box-sizing: border-box;
    padding: 20px;
    border: 1px dotted #009ee1;
    background-color: #f0f0f0;
    border-radius: 5px;
    width: 90%;
    margin: 10px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    text-align: center; }
    @media screen and (min-width: 769px) {
      .blog article {
        width: 45%; } }
    @media screen and (min-width: 960px) {
      .blog article {
        width: 30%; } }
    .blog article figure {
      width: 100%;
      height: auto;
      margin: 0;
      padding: 0;
      overflow: hidden; }
    .blog article img {
      -webkit-transition: all 0.2s ease-in-out;
      transition: all 0.2s ease-in-out;
      max-width: 100%;
      height: auto; }
    .blog article h2 {
      margin: 10px 0 0;
      line-height: 1.2em;
      font-weight: normal; }
    .blog article h3 {
      margin: 0;
      font-weight: normal;
      font-size: 0.8em;
      color: #6d6e71; }
    .blog article:hover {
      background-color: #fff; }
      .blog article:hover img {
        -webkit-transform: scale(1.1);
                transform: scale(1.1); }

.blogsingle {
  background-color: #fff; }
  @media screen and (min-width: 769px) {
    .blogsingle {
      background-color: #f0f0f0;
      background: -webkit-linear-gradient(bottom, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%);
      background: linear-gradient(0deg, #ccecf9 1%, #f0f0f0 50%, #99d8f3 99%); } }

.blogsingle .content {
  background: #fff;
  padding-top: 3em;
  width: 100%;
  margin: 20px auto; }
  @media screen and (min-width: 769px) {
    .blogsingle .content {
      width: 90%;
      max-width: 1200px; } }
  .blogsingle .content p,
  .blogsingle .content h1,
  .blogsingle .content h2,
  .blogsingle .content h3,
  .blogsingle .content h4,
  .blogsingle .content h5,
  .blogsingle .content h6 {
    padding-left: 20px;
    padding-right: 20px; }
  .blogsingle .content img {
    max-width: 100%;
    height: auto;
    margin-top: 2em;
    margin-bottom: 2em; }

.bookingstep .zaglavlje {
  padding-bottom: 8px; }
  .bookingstep .zaglavlje .userbar {
    margin-bottom: 0; }

.bookingstep .content {
  background-color: #f0f0f0;
  background: -webkit-linear-gradient(bottom, #f0f0f0, #f0f0f0 60%, #99d8f3 99%);
  background: linear-gradient(0deg, #f0f0f0, #f0f0f0 60%, #99d8f3 99%); }

.bookingstep-content {
  background-color: #fff;
  background-color: #f0f0f0;
  max-width: 960px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 2em; }
  .bookingstep-content .header-booking {
    background-color: #f2f2f2;
    background-color: #009ee1;
    padding: 10px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    @media screen and (min-width: 769px) {
      .bookingstep-content .header-booking {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
  .bookingstep-content .booking-note {
    background-color: #f2f2f2;
    padding: 10px 20px; }

.bizletname {
  font-weight: normal; }

.bizlet {
  color: #102c69;
  line-height: 1.2em;
  margin-top: 0;
  background-color: #f2f2f2;
  padding: 10px 20px; }
  .bizlet span {
    font-weight: normal;
    color: #009ee1; }

.bdateall {
  display: inline-block;
  border: 1px dotted #009ee1; }
  .bdateall .bdate {
    margin: 0;
    padding: 5px 10px;
    font-weight: bold;
    border-bottom: 1px dotted #009ee1; }
  .bdateall .bday {
    margin: 0;
    padding: 5px 10px;
    font-weight: 0.9em;
    text-transform: uppercase; }

.bstep {
  text-align: right;
  background-color: #efefef; }
  .bstep h2 {
    display: inline-block;
    padding: 10px 20px;
    margin: 1em 0 0;
    background-color: #009ee1;
    color: #fff;
    font-size: 2em;
    font-weight: normal; }

.bstep1 .bstep h2,
.bstep1 .header-booking {
  background-color: #009ee1;
  color: #fff; }

.bstep1 .header-booking {
  background: -webkit-linear-gradient(45deg, #102c69 0%, #009ee1 80%);
  background: linear-gradient(45deg, #102c69 0%, #009ee1 80%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.bstep1 .bizletname {
  color: #fff; }

.bstep1 .bdateall {
  border: 1px dotted #fff; }
  .bstep1 .bdateall .bdate {
    border-bottom: 1px dotted #fff; }

.bstep2 .bstep h2,
.bstep2 .header-booking,
.bstep2 .booking-note {
  background-color: #102c69;
  color: #fff; }

.bstep2 .header-booking,
.bstep2 .booking-note {
  background: -webkit-linear-gradient(45deg, #009ee1 0%, #102c69 80%);
  background: linear-gradient(45deg, #009ee1 0%, #102c69 80%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */ }

.bstep2 .bizletname {
  color: #fff; }

.bstep2 .bdateall {
  border: 1px dotted #fff; }
  .bstep2 .bdateall .bdate {
    border-bottom: 1px dotted #fff; }

.bookingform {
  margin: 1em 0;
  padding: 0;
  list-style-type: none; }

.bookingform li {
  counter-increment: step-counter;
  margin-bottom: 2em;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column; }
  @media screen and (min-width: 769px) {
    .bookingform li {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; } }
  .bookingform li:not(:last-child) {
    background-color: #fff;
    padding: 2em;
    border: 1px dotted #009ee1; }

.bookingform li:not(:last-child):before {
  content: counter(step-counter);
  background-color: #009ee1;
  color: #fff;
  font-weight: bold;
  padding: 1em;
  width: 1em;
  height: 1em;
  text-align: center;
  border-radius: 1.5em;
  line-height: 1em; }

.bookingform li.next {
  padding-top: 1em;
  display: block;
  text-align: right; }

.bookingform li.promocode {
  background-color: #ffca00; }

.bfitem {
  width: 100%; }
  @media screen and (min-width: 769px) {
    .bfitem {
      padding-left: 3em; } }
  .bfitem h3 {
    text-align: left;
    margin-top: 0; }

.bookingopcije label {
  display: block;
  margin-bottom: 5px; }

.formbook label.required:after {
  content: '*';
  font-weight: bold;
  color: #009ee1; }

.formbook label.required:hover:after {
  content: ' REQUIRED';
  font-size: 0.8em;
  font-weight: normal; }

.bookingrow {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  margin-bottom: 15px; }
  @media screen and (min-width: 769px) {
    .bookingrow {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      width: 90%;
      margin-bottom: 5px; } }
  .bookingrow label {
    cursor: pointer;
    width: 100%;
    cursor: pointer; }
    @media screen and (min-width: 769px) {
      .bookingrow label {
        width: 50%; } }
  .bookingrow input,
  .bookingrow select {
    width: 50px;
    margin-left: auto; }
  .bookingrow p {
    margin: 0 0 0 1em;
    text-align: left;
    width: 30%; }

#nextbuton {
  padding: 7px 12px;
  border: none;
  background: #63b340;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: 2px; }
  #nextbuton:hover {
    cursor: pointer;
    background: #52883a; }

#resetbuton {
  margin-left: 20px;
  padding: 7px 12px;
  border: none;
  background: #f92460;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: .05em;
  border-radius: 2px; }
  #resetbuton:hover {
    cursor: pointer;
    background: #102c69; }

.formbook select {
  box-sizing: border-box;
  background: transparent;
  border: none;
  background-color: #e6f5fc;
  font-size: 1em;
  color: #99d8f3;
  padding: 5px;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
  background-position: 100% 50%;
  background-repeat: no-repeat;
  /* If you add too much padding here, the options won't show in IE */ }
  .formbook select:focus {
    color: #102c69; }
  .formbook select:invalid {
    border-right: 20px solid #f92460; }
  .formbook select:valid {
    border-right: 20px solid #63b340;
    color: #102c69; }
  .formbook select:optional {
    border-right: 20px solid #e6f5fc; }
  .formbook select:optional:invalid {
    border-right: 20px solid #f92460; }
  .formbook select.error {
    border-right: 20px solid #f92460; }

.formbook textarea {
  box-sizing: border-box;
  background: transparent;
  border: none;
  background-color: #e6f5fc;
  font-size: 1em;
  color: #102c69;
  padding: 5px;
  /* If you add too much padding here, the options won't show in IE */ }
  .formbook textarea:focus {
    color: #102c69; }
  .formbook textarea:invalid,
  .formbook textarea :invalid::-webkit-input-placeholder {
    border-right: 20px solid #f92460; }
  .formbook textarea:invalid,
  .formbook textarea :invalid::-moz-placeholder {
    border-right: 20px solid #f92460; }
  .formbook textarea:invalid,
  .formbook textarea :invalid:-ms-input-placeholder {
    border-right: 20px solid #f92460; }
  .formbook textarea:invalid,
  .formbook textarea :invalid::placeholder {
    border-right: 20px solid #f92460; }
  .formbook textarea:valid {
    border-right: 20px solid #63b340; }
  .formbook textarea:optional {
    border-right: 20px solid #e6f5fc; }
  .formbook textarea:optional:invalid {
    border-right: 20px solid #f92460; }
  .formbook textarea.error {
    border-right: 20px solid #f92460; }

.formbook ::-webkit-input-placeholder {
  color: #99d8f3; }

.formbook ::-moz-placeholder {
  color: #99d8f3; }

.formbook :-ms-input-placeholder {
  color: #99d8f3; }

.formbook ::placeholder {
  color: #99d8f3; }

.formbook .upute {
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-align: center;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-height: 0;
  overflow: hidden;
  background-color: #f92460;
  padding: 0px;
  margin: 0 auto 0px; }

.formbook .form-error {
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-align: center;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  font-size: 0.8em;
  color: #f92460;
  padding: 2px;
  margin: 8px auto 20px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

.formbook :focus:invalid + .upute {
  font-size: 0.8em;
  display: block;
  padding: 2px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  color: #fff;
  max-height: 300px;
  margin: 8px auto 20px; }

.formbook :focus + .form-error {
  box-sizing: border-box;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  text-align: center;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  font-size: 0.8em;
  color: #fff;
  background-color: #f92460;
  padding: 2px;
  margin: 8px auto 20px;
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center; }

.formbook .uputemail {
  font-size: 0.8em;
  box-sizing: border-box;
  text-align: center;
  width: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  background-color: #f92460;
  color: #fff;
  padding: 5px;
  margin: 0 auto 0px; }

.formbook input[type=text],
.formbook input[type=email],
.formbook input[type=tel],
.formbook input[type=password] {
  box-sizing: border-box;
  background: transparent;
  border: none;
  background-color: #e6f5fc;
  font-size: 1em;
  color: #102c69;
  padding: 5px;
  /* If you add too much padding here, the options won't show in IE */
  width: 100%; }
  .formbook input[type=text]:focus,
  .formbook input[type=email]:focus,
  .formbook input[type=tel]:focus,
  .formbook input[type=password]:focus {
    color: #102c69; }
  .formbook input[type=text]:invalid,
  .formbook input[type=text] :invalid::-webkit-input-placeholder,
  .formbook input[type=email]:invalid,
  .formbook input[type=email] :invalid::-webkit-input-placeholder,
  .formbook input[type=tel]:invalid,
  .formbook input[type=tel] :invalid::-webkit-input-placeholder,
  .formbook input[type=password]:invalid,
  .formbook input[type=password] :invalid::-webkit-input-placeholder {
    border-right: 20px solid #f92460; }
  .formbook input[type=text]:invalid,
  .formbook input[type=text] :invalid::-moz-placeholder,
  .formbook input[type=email]:invalid,
  .formbook input[type=email] :invalid::-moz-placeholder,
  .formbook input[type=tel]:invalid,
  .formbook input[type=tel] :invalid::-moz-placeholder,
  .formbook input[type=password]:invalid,
  .formbook input[type=password] :invalid::-moz-placeholder {
    border-right: 20px solid #f92460; }
  .formbook input[type=text]:invalid,
  .formbook input[type=text] :invalid:-ms-input-placeholder,
  .formbook input[type=email]:invalid,
  .formbook input[type=email] :invalid:-ms-input-placeholder,
  .formbook input[type=tel]:invalid,
  .formbook input[type=tel] :invalid:-ms-input-placeholder,
  .formbook input[type=password]:invalid,
  .formbook input[type=password] :invalid:-ms-input-placeholder {
    border-right: 20px solid #f92460; }
  .formbook input[type=text]:invalid,
  .formbook input[type=text] :invalid::placeholder,
  .formbook input[type=email]:invalid,
  .formbook input[type=email] :invalid::placeholder,
  .formbook input[type=tel]:invalid,
  .formbook input[type=tel] :invalid::placeholder,
  .formbook input[type=password]:invalid,
  .formbook input[type=password] :invalid::placeholder {
    border-right: 20px solid #f92460; }
  .formbook input[type=text]:valid,
  .formbook input[type=email]:valid,
  .formbook input[type=tel]:valid,
  .formbook input[type=password]:valid {
    border-right: 20px solid #63b340; }
  .formbook input[type=text]:optional,
  .formbook input[type=email]:optional,
  .formbook input[type=tel]:optional,
  .formbook input[type=password]:optional {
    border-right: 20px solid #e6f5fc; }
  .formbook input[type=text]:optional:invalid,
  .formbook input[type=email]:optional:invalid,
  .formbook input[type=tel]:optional:invalid,
  .formbook input[type=password]:optional:invalid {
    border-right: 20px solid #f92460; }
  .formbook input[type=text].error,
  .formbook input[type=email].error,
  .formbook input[type=tel].error,
  .formbook input[type=password].error {
    border-right: 20px solid #f92460; }
  @media screen and (min-width: 769px) {
    .formbook input[type=text],
    .formbook input[type=email],
    .formbook input[type=tel],
    .formbook input[type=password] {
      width: 45%; } }

.formbook input[type=radio] {
  -webkit-appearance: radio;
  display: inline-block;
  width: auto;
  background-color: #99f; }

.formbook textarea {
  width: 100%; }

.formbook input[type=number] {
  width: 50px; }

.formbook select {
  width: 100%; }
  @media screen and (min-width: 769px) {
    .formbook select {
      width: 45%; } }

.book-assistance {
  padding: 2em;
  margin-bottom: 0;
  text-align: center; }
  .book-assistance a {
    margin: 1em auto;
    font-size: 1.3em;
    padding: 5px 10px;
    border: 1px dotted #009ee1; }

.note-securebook {
  text-align: center;
  color: #6d6e71; }

.obracunp {
  display: inline-block;
  color: #6d6e71;
  padding: 1.5em;
  border: 1px dotted #6d6e71; }
  .obracunp .subtotal {
    border-top: solid 1px #6d6e71;
    font-weight: bold;
    display: block;
    margin: 0 0 5px; }
  .obracunp .total {
    border-top: solid 1px #6d6e71;
    font-weight: bold;
    font-size: 1.1em;
    display: block; }

.confirmation {
  background-color: #fff; }
  .confirmation .bstep {
    background-color: #fff; }
  .confirmation h3 {
    text-align: left; }

.bstavke {
  padding: 2em 0;
  margin: 0;
  list-style-type: none; }
  .bstavke li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    padding: 0;
    list-style-type: none; }
    .bstavke li:not(:last-child) {
      border-bottom: 1px dotted #102c69;
      margin-bottom: 1.5em;
      padding-bottom: 1.5em; }
    .bstavke li p {
      -webkit-margin-before: 0;
      -webkit-margin-after: 0;
      margin: 0; }
    .bstavke li .bstavka-label {
      color: #009ee1;
      box-sizing: border-box;
      text-transform: uppercase;
      -webkit-box-flex: 0;
          -ms-flex: 0 0 30%;
              flex: 0 0 30%;
      min-width: 150px;
      margin-right: 20px; }
    .bstavke li .bstavka-opis {
      color: #102c69;
      box-sizing: border-box;
      -webkit-box-flex: 0;
          -ms-flex: 0 1 60%;
              flex: 0 1 60%;
      min-width: 300px; }

.bnapomena {
  color: #6d6e71; }

.remember {
  margin: 0 0 1em auto;
  background-color: #f92460;
  box-sizing: border-box;
  padding: 2em;
  color: #fff; }
  .remember h3 {
    text-align: center;
    margin: 0; }
  .remember p {
    text-align: center;
    margin: 0 0 1em 0; }
  .remember .remember-opcije {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center; }
    .remember .remember-opcije a {
      margin-top: 1em;
      padding: 0.5em 1em;
      border: dotted 1px #fff;
      color: #fff; }
      .remember .remember-opcije a:before {
        content: '';
        font-size: 0.8em;
        width: 2em;
        height: 2em;
        background-position: -16.9375em -15.8125em;
        margin-right: 5px; }
        .lt-ie9 .remember .remember-opcije a:before {
          width: 32px;
          height: 32px;
          background-position: -271px -253px; }
      .remember .remember-opcije a:not(:last-child) {
        margin-right: 1em; }
      .remember .remember-opcije a:hover {
        background-color: #ffca00;
        color: #000; }

.bookingopcije input[type="radio"] {
  display: none; }

.bookingopcije input[type="radio"] + label {
  display: inline;
  margin: 0; }

.bookingopcije input[type="radio"] + label span {
  display: inline-block;
  width: 19px;
  height: 19px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%; }

.bookingopcije input[type="radio"] + label span {
  background-color: #e6f5fc; }

.bookingopcije input[type="radio"]:checked + label span {
  background-color: #63b340; }

.bookingopcije input[type="radio"] + label span,
.bookingopcije input[type="radio"]:checked + label span {
  -webkit-transition: background-color 0.3s linear;
  transition: background-color 0.3s linear; }

.menubuton {
  text-indent: -150%;
  overflow: hidden;
  background-color: #ffca00;
  border: 4px solid #ffca00;
  font-size: 1.2em;
  z-index: 9999;
  position: fixed;
  top: 11px;
  right: 15px;
  margin: 0;
  width: 1em;
  height: 1em;
  background-position: -13.3125em -13.6875em;
  -webkit-transition: font-size 0.3s linear;
  transition: font-size 0.3s linear; }
  .lt-ie9 .menubuton {
    width: 16px;
    height: 16px;
    background-position: -213px -219px; }
  .menubuton:hover {
    background-color: #fff;
    border-color: #fff;
    cursor: pointer;
    font-size: 1.3em; }

.menuout .menubuton {
  background-position: -11.6875em -13.6875em; }
  .lt-ie9 .menuout .menubuton {
    background-position: -187px -219px; }

.content {
  position: relative; }

.menuout .content {
  overflow: hidden; }

.mainmenu {
  max-width: 960px;
  margin: auto;
  padding: 20px;
  color: #99d8f3;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column;
          flex-flow: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  text-align: center;
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear; }
  .mainmenu a,
  .mainmenu a:visited,
  .mainmenu a:link {
    color: #fff; }
    .mainmenu a:hover,
    .mainmenu a:visited:hover,
    .mainmenu a:link:hover {
      color: #009ee1; }

.mainmenuoverlay {
  /* Height & width depends on how you want to reveal the overlay (see JS below) */
  height: 100%;
  width: 0;
  position: fixed;
  /* Stay in place */
  z-index: 99;
  /* Sit on top */
  right: 0;
  top: 0;
  background-color: #102c69;
  background-color: rgba(16, 44, 105, 0.94);
  overflow-x: hidden;
  overflow-y: hidden;
  /* Disable horizontal scroll */
  -webkit-transition: 0.4s;
  transition: 0.4s;
  /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  z-index: 999; }

.visible {
  width: 100vw;
  overflow-y: auto; }
  @media screen and (min-width: 960px) {
    .visible {
      width: 60vw; } }

.menutitle {
  margin: 1em auto;
  font-size: 0.5em;
  text-indent: -200%;
  overflow: hidden;
  width: 32.25em;
  height: 4.875em;
  background-position: -0.3125em -8.1875em; }
  @media screen and (min-width: 769px) {
    .menutitle {
      font-size: 0.7em; } }
  .lt-ie9 .menutitle {
    width: 516px;
    height: 78px;
    background-position: -5px -131px; }

.menujezik ul,
.menujezik li {
  list-style-type: none !important;
  list-style-image: none !important;
  margin: 0;
  padding: 0; }

.menujezik ul {
  padding: 20px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }

.menujezik a {
  box-sizing: border-box;
  display: block; }

.mainmenu .menujezik a {
  padding: 0.6em 0.8em; }
  .mainmenu .menujezik a.active {
    border-bottom: 1px dotted #ffca00; }
  .mainmenu .menujezik a:hover, .mainmenu .menujezik a:hover.active {
    background-color: #fff; }

@media screen and (min-width: 960px) {
  .mainmenu .menujezik {
    display: none; } }

.partnerlogin {
  -ms-flex-item-align: center;
      -ms-grid-row-align: center;
      align-self: center;
  margin: 1.5em;
  padding: 0.5em 1em;
  border: 1px dotted #fff; }

.menuizlet {
  position: relative;
  width: 90vw;
  height: 60vw;
  max-height: 400px;
  max-width: 600px;
  margin: 1em auto; }
  .menuizlet .izletipasica h2 {
    text-align: center;
    font-size: 1.2em;
    font-weight: normal; }

.menuizleti {
  display: none; }
  @media screen and (min-width: 960px) {
    .menuizleti {
      padding: 0.2em 0;
      margin: 0 auto;
      max-width: 1000px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; }
      .menuizleti a {
        display: block;
        font-size: 1em;
        padding: 0.5em;
        line-height: 1.2em;
        font-weight: 400;
        -webkit-transform: all 0.2s linear;
                transform: all 0.2s linear; } }

.submenuizleti {
  background-color: #009ee1;
  box-sizing: content-box;
  margin: 2px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 100%;
          flex: 1 1 100%; }
  .submenuizleti a:last-child {
    margin-bottom: 10px; }
  .submenuizleti a:hover,
  .submenuizleti a:visited:hover,
  .submenuizleti a:link:hover {
    color: #102c69; }
  @media screen and (min-width: 769px) {
    .submenuizleti {
      -webkit-box-flex: 1;
          -ms-flex: 1 1 30%;
              flex: 1 1 30%; } }

.menuglavnestavke {
  padding: 0.5em 1.5em;
  margin: 0 auto;
  border-bottom: 1px dotted #b7c0d2; }
  .menuglavnestavke a {
    display: block;
    font-size: 1.2em;
    padding: 0.5em 0;
    font-weight: normal;
    -webkit-transform: all 0.2s linear;
            transform: all 0.2s linear; }
  @media screen and (min-width: 960px) {
    .menuglavnestavke {
      border: none; } }

.menustavke {
  padding: 0.2em 1.5em;
  margin: 0 auto 1.5em; }
  .menustavke a {
    display: block;
    padding: 0.5em 0;
    font-weight: normal;
    -webkit-transform: all 0.2s linear;
            transform: all 0.2s linear; }

.menusocialnetwork a,
.menusocialnetwork a:visited {
  text-indent: 9999px;
  font-size: 1.4em;
  margin: 2px;
  border: 4px solid #ffca00;
  display: inline-block;
  overflow: hidden; }
  .menusocialnetwork a:hover,
  .menusocialnetwork a:visited:hover {
    background-color: #fff;
    border: 4px solid #fff; }

.mainmenu .menusocialnetwork a,
.mainmenu .menusocialnetwork a:visited {
  background-color: #fff;
  border: 4px solid #fff; }
  .mainmenu .menusocialnetwork a:hover,
  .mainmenu .menusocialnetwork a:visited:hover {
    background-color: #009ee1;
    border: 4px solid #009ee1; }

.menukontakti {
  font-size: 0.9em;
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .menukontakti a,
  .menukontakti p {
    margin-left: 0.5em; }

.contact-skype {
  text-indent: -200%;
  width: 2em;
  height: 2em;
  background-position: -19.5625em -15.8125em;
  overflow: hidden; }
  .lt-ie9 .contact-skype {
    width: 32px;
    height: 32px;
    background-position: -313px -253px; }

.contact-viber {
  text-indent: -200%;
  overflow: hidden;
  width: 2em;
  height: 2em;
  background-position: -11.6875em -15.8125em; }
  .lt-ie9 .contact-viber {
    width: 32px;
    height: 32px;
    background-position: -187px -253px; }

.contact-whatsup {
  text-indent: -200%;
  overflow: hidden;
  width: 2em;
  height: 2em;
  background-position: -9.0625em -15.8125em; }
  .lt-ie9 .contact-whatsup {
    width: 32px;
    height: 32px;
    background-position: -145px -253px; }

.mailbook {
  display: none; }

.login .mailbook {
  display: block; }

.login .mailinfo {
  display: none; }

.businessmenu {
  font-size: 0.9em;
  margin-bottom: 1em; }
  .businessmenu a:not(:last-child) {
    padding-right: 1em;
    margin-right: 1em;
    border-right: 1px dotted #009ee1; }

@media print {
  /* All your print styles go here */
  body * {
    font-size: 11px !important;
    line-height: 12.5px;
    color: #000 !important;
    background-color: #fff !important; }
  h1 {
    font-size: 18px !important; }
  h2 {
    font-size: 16px !important; }
  h3 {
    font-size: 14px !important;
    margin: 5px 0;
    font-weight: bold; }
  h4 {
    font-size: 12px !important;
    font-weight: bold; }
  img {
    max-width: 50%;
    height: auto; }
  body .content,
  body .bookingstep .content {
    background: none !important;
    background-color: #fff !important; }
  body * {
    box-sizing: border-box; }
  *.printwrap > *:not(.print):not(.printwrap) {
    display: none !important;
    visibility: hidden;
    padding: 0;
    margin: 0;
    height: 0; }
  *.printwrap > *.printwrap {
    display: block !important;
    margin-top: 0;
    visibility: visible; }
  *.printwrap *.print {
    display: block !important;
    height: auto;
    margin-top: 0;
    visibility: visible;
    width: 98%;
    max-width: 98%; }
  * .harmo:after {
    display: none; }
  * .nika {
    display: block !important;
    overflow: visible;
    height: auto !important;
    max-height: 999999px; }
  * .printlink {
    display: none; }
  * .remember {
    visibility: hidden;
    height: 0;
    display: none !important; }
  * .bstavke {
    padding: 15px 0; } }

/*# sourceMappingURL=style.css.map */
