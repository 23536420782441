.owl-theme {
    // Styling Next and Prev buttons
    .owl-nav {
        margin-top: 10px;
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        [class*='owl-'] {
            color: $nav-color;
            font-size: $nav-font-size;
            margin: $nav-margin;
            padding: $nav-padding;
            background: $nav-background;
            display: inline-block;
            cursor: pointer;
            border-radius: 1px;
            &:hover {
                background: $nav-background-hover;
                color: $nav-color-hover;
                text-decoration: none;
            }
        }
        .disabled {
            opacity: $nav-disabled-opacity;
            cursor: default;
        }
    }
    // Styling dots
    .owl-nav.disabled+.owl-dots {
        margin-top: 10px;
    }
    .owl-dots {
        text-align: center;
        -webkit-tap-highlight-color: transparent;
        .owl-dot {
            display: inline-block;
            zoom: 1;
            *display: inline;
            span {
                width: $dot-width;
                height: $dot-height;
                margin: $dot-margin;
                background: $dot-background;
                display: block;
                -webkit-backface-visibility: visible;
                transition: opacity 200ms ease;
                border-radius: 1px;
            }
            &.active,
            &:hover {
                span {
                    background: $dot-background-active;
                }
            }
        }
    }
}