/* ===========================
Base
=========================== */

html {
    line-height: 1em;
}

body {
    font-family: $fontglavni;
    font-weight: 400;
    font-size: 1em;
    line-height: 1.3em;
    color: $tplava;
    height: 100%;
}

.menuout .content {
    // overflow: auto;
}

.menuout .mainmenu {
    // overflow: scroll;
}

li {
    list-style-type: square;
}

b,
strong {
    font-weight: 700;
}

a {
    color: $color-link;
    text-decoration: none;
    transition: all 0.2s linear;
    &:hover {
        color: $color-link-hover;
    }
}

 ::-moz-selection {
    background: #f00;
    color: #fff;
    text-shadow: none;
}

 ::selection {
    background: #f00;
    color: #fff;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}


/* ==|====================
Layout/Header
======================= */

.zaglavlje {
    // background-color: $zuta;
    background-color: $tplava;
    background-image: none;
    color: $tplava;
}

.fancy {
    font-family: 'Kaushan Script', cursive;
}

h2.fancy {
    font-size: 3em;
    color: $plava;
}

h1 {
    color: $plava;
    font-size: 2em;
}

h1.singletitle {
    text-align: center;
    font-size: 3.5em;
    color: #fff;
}

h2 {
    line-height: 1.1em;
}

.logo {
    font-size: 0.5em;
    @include breakpoint($tablet) {
        font-size: 7px;
    }
    @include breakpoint($large) {
        font-size: 0.6em;
    }
    @include breakpoint($wide) {
        font-size: 0.71em;
    }
    width: 20vw;
    text-indent: -200%;
    overflow: hidden;
    @include sprite(croatiaexcursion-logo, all);
    // margin-bottom:10px;
}

.footer {
    padding: 2em;
    font-size: 1em;
    background-color: $zuta;
    text-align: center;
    & .printcontacts {
        margin-top: 2em;
        border-top: 1px solid #333;
        display: none;
    }
}

.copy {
    font-size: 0.6em;
}

.userbar {
    font-size: 0.8em;
    // background-color: $tplava;
    background-color: $zuta;
    color: #fff;
    margin-bottom: 8px;
    & .menujezik a {
        // font-weight: bold;
        padding: 0.2em 0.3em;
        color: $tplava;
        &.active {
            // border-bottom: 1px dotted $zelena;
            color: $zelena;
        }
        &:hover,
        &:hover.active {
            // background-color: #fff;
            color: $zelena;
        }
    }
}

.userbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // min-height: 2em;
    flex-wrap: wrap;
    padding-right: 50px;
    & .branding {}
    & .space {
        flex: 10 auto;
    }
    & .menujezik {
        display: none;
        @include breakpoint($tablet) {
            display: block;
            flex: 0 0 auto;
            margin: .2em .4em;
            & ul {
                padding: 0;
            }
            border-right: 1px dotted $tplava;
        }
        @include breakpoint($wide) {
            margin: .2em 1em;
            border-right: none;
        }
    }
    & .menukontakti {
        display: none;
        @include breakpoint($tablet) {
            display: flex;
            margin: .2em .4em;
            color: $plava;
            flex: 0 0 auto;
            // padding: 0.2em 2em;
            // font-size: 0.8em;
            // margin: .2em 1em;
            // padding: 0;
            & a {
                color: $tplava;
                padding: 0.2em 0.3em;
            }
            border-right: 1px dotted $tplava;
        }
        @include breakpoint($wide) {
            margin: .2em 1em;
            border-right: none;
        }
    }
    & .userlogin {
        display: none;
        @include breakpoint($tablet) {
            display: block;
            flex: 0 0 auto;
            // margin: .2em 4.5em 0.2em 1.5em;
            margin: .2em .8em 0.2em 0.4em;
            // padding: 0.2em 0.3em;
            color: $tplava;
            background-color: #fff;
            // font-size: 0.8em;
            &:hover {
                background-color: $ssiva;
            }
        }
    }
    & .currentuser {
        display: block;
        // flex: 1 1 100%;
        background-color: transparent;
        margin: .2em auto 0.2em auto;
        padding: 0.2em 0.3em;
        // @include breakpoint($tablet) {
        display: block;
        flex: 0 0 auto;
        color: $tplava;
        // background-color: #fff;
        // font-size: 0.8em;
        &:hover {
            background-color: transparent;
        }
        // }
    }
    // .userlogin {
    //     @include breakpoint($tablet) {
    //         display: block;
    //     }
    // }
}

// test
// header
// PRIKAZ IZLETA U PROMO TRACI 
.promoizleti {
    margin: 2em auto;
    // margin-top: 2em;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    // & .promoizlet {
    //     @include breakpoint($wide) {
    //         flex: 1 1 22%;
    //         max-width: 450px;
    //     }
    // }
}

.promoizlet {
    // width: 90vw;
    flex: 1 1 100vw;
    margin-bottom: 20px;
    
    // flex-wrap: no-wrap;
    @include breakpoint($tablet) {
        flex: 1 1 45%;
        margin: 10px;
        max-width: 460px;
        min-height: 450px;
    }
    @include breakpoint($large) {
        flex: 1 1 30%;
        max-width: 380px;
        min-height: 470px;
    }
    @include breakpoint($wide) {
        flex: 1 1 22%;
        max-width: 450px;
    }
    max-width: $tablet;
    display: flex;
    box-sizing: border-box;
    // height: 80vh;
    flex-direction: column;
    & header {
        // flex: 1 1 20%; // Internet explorer 11 ne radi!
        flex: 1 1 auto;
        order: 2;
        background-color: #eee;
        padding: 1em 0.5em 0.2em;
        box-sizing: border-box;
        width: 100%;
        height: auto;
        display: block;
        & h2,
        h3 {
            margin: 0;
            padding: 0;
        }
        & h2 {
            // font-size: 1.3em;
            // font-weight: normal;
            // margin-top: 0.5em;
            line-height: 1.1em;
        }
        & h3 {
            color: $plava;
            font-weight: normal;
            font-size: 1em;
            margin-top: 0.3em;
        }
        & a h2 {
            color: $tplava;
        }
        & a h3 {
            color: $plava;
        }
        &:hover {
            background-color: $zuta;
            & h2,
            h3 {
                color: $tplava;
            }
        }
    }
    & .bookinglist {
        order: 3;
    }
}

.promoizletblok {
    // flex: 1 1 80vw;
    // min-height: 350px; // stara mjera - malo previsoko na mobitelu 300px na desktopu
    min-height: 220px;
    @include breakpoint($tablet) {
        min-height: 200px;
    }
    // height: 60vw;
    background-size: cover;
    background-position: 50% 50%;
    // flex: 1 1 auto;
    flex-direction: row;
    // height: 30vh;
    display: flex;
    // align-items: stretch;
    justify-content: space-between;
}

.izletbrief {
    background-color: rgba(#fff, 0.2);
    // align-self: flex-start;
    text-align: center;
    // display: flex;
    flex: 0 1 auto;
    align-self: auto;
    padding: 20px 20px;
    color: #fff;
    & .cijenaod {
        display: block;
        margin: 0;
        line-height: 1.1em;
        font-size: 0.45em;
        font-weight: normal;
        text-transform: uppercase;
        text-align: left;
    }
    & .cijena {
        font-size: 1.6em;
        line-height: 1em;
        padding: 10px 0 0;
        margin: 0;
        font-weight: bold;
        border-top-width: 2px;
        border-top-style: dotted;
        &:after {
            content: " KN";
            font-size: 0.5em;
            font-weight: normal;
        }
    }
    & .cijenafrom:before {
        content: "FROM";
        display: block;
        margin: 0;
        line-height: 1.1em;
        font-size: 0.45em;
        font-weight: normal;
        text-align: left;
    }
    & .cijenaodhr:before {
        content: "OD";
        display: block;
        margin: 0;
        line-height: 1.1em;
        font-size: 0.45em;
        font-weight: normal;
        text-align: left;
    }
    & .cijenaeur {
        font-size: 1.6em;
        line-height: 1em;
        padding: 0 0 10px 0;
        margin: 0;
        font-weight: bold;
        // border-top-width: 2px;
        // border-top-style: dotted;
        &:after {
            content: " EUR";
            font-size: 0.5em;
            font-weight: normal;
        }
    }
    & .trajanje {
        position: relative;
        font-size: 2em;
        line-height: 0.8em;
        padding: 0;
        margin: 0;
        font-weight: bold;
        &:before {
            content: "\2248";
            font-size: 0.5em;
            font-weight: normal;
        }
        &:after {
            content: "h";
            font-size: 0.5em;
            font-weight: normal;
        }
    }
    &:before {
        // ikona tipa izleta
        content: '';
        opacity: 0.5;
        margin: 0;
        font-size: 1.2em;
        margin-bottom: -0.8em;
    }
}

a.booknow {
    // position: absolute;
    // top: 20px;
    // right: 20px;
    flex: 0 1 auto;
    align-self: flex-start;
    // align-self: flex-start;
    // z-index: 99;
    font-size: 1em;
    margin: 10px 0;
    @include breakpoint($tablet) {
        margin: 10px;
    }
    // position: absolute;
    // right: 10px;
    // bottom: 10px;
    border: 1px solid #fff;
    padding: 8px 20px;
    color: #fff;
    text-decoration: none;
    transition: all 0.18s linear;
    background-color: $zelena-buton;
    background: linear-gradient(6deg, $tplava 5%, $plava 35%, $zelena 65%, $zelena-buton 95%);
    background-size: 320%;
    background-position: 1% 1%;
    &:hover {
        background-color: $plava;
        background-position: 99% 99%;
    }
}

.printlink {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.8em;
    text-transform: uppercase;
    color: #333;
    background-color: #f0f0f0;
    &:hover {
        color: $plava;
        background-color: #fff;
    }
    padding: 0.5em 1.4em;
    border: 1px solid #333;
    border-radius: 2em;
    display: inline-block;
    line-height:2em;
    &:before {
        content: '';
        font-size: 0.8em;
        @include sprite('print', all);
        margin-right: 5px;
        position: relative;
        top: 0.55em;
    }
}

.bojazuta {
    & .izletbrief {
        color: $tplava;
        background-color: $zuta;
        background-color: rgba($zuta, 0.7);
        background: linear-gradient(to bottom, rgba($zuta, 1), rgba($zuta, 0));
    }
}

.bojasiva {
    & .izletbrief {
        color: $zuta;
        background-color: $siva;
        background-color: rgba($siva, 0.7);
        background: linear-gradient(to bottom, rgba($siva, 1), rgba($siva, 0));
    }
}

.bojazelena {
    & .izletbrief {
        color: #fff;
        background-color: $zelena;
        background-color: rgba($zelena, 0.5);
        background: linear-gradient(to bottom, rgba($zelena, 1), rgba($zelena, 0));
    }
}

.bojaplava {
    & .izletbrief {
        color: #fff;
        background-color: $plava;
        background-color: rgba($plava, 0.8);
        background: linear-gradient(to bottom, rgba($plava, 1), rgba($plava, 0));
    }
}

.bojatplava {
    & .izletbrief {
        color: #fff;
        background-color: $tplava;
        background-color: rgba($tplava, 0.8);
        background: linear-gradient(to bottom, rgba($tplava, 1), rgba($plava, 0));
    }
}

.bojamplava {
    & .izletbrief {
        color: #fff;
        background-color: $mplava;
        background-color: rgba($mplava, 0.8);
        background: linear-gradient(to bottom, rgba($mplava, 1), rgba($mplava, 0));
    }
}

// PROMO sekcija
.promo {
    background-color: #fff;
}

#promo-zaglavlje {
    & .owl-stage-outer {
        width: 100%;
    }
    & .owl-item {
        display: block;
        width: 100%;
        height: auto;
    }
}

// promo slike na početnoj stranici
.promoslide {
    display: block;
    // background-color: #f00;
    padding: 0;
    margin: 0;
    width: 100%;
    // height: auto;
    height: 55vh;
    text-align: center;
    background-size: cover;
    background-position: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include breakpoint($okomiti) {
        height: 50vh;
    }
    @include breakpoint($vodoravni) {
        height: 75vh;
    }
}

// promo slika na stranici izleta
.headerpromo {
    width: 100%;
    min-height: 50vh;
    background-color: $plava;
}

.headerpromoinside {
    margin: 0 auto;
    // max-width: 90vw;  // ne radi u IE11
    width: 100%;
    align-self: center;
    text-align: center;
    color: #fff;
    & h1 {
        width: 80%;
        max-width: 90vw;
        line-height: 1em;
        color: #fff;
        margin: 0.2em auto;
        font-size: 3em;
        font-size: calc(1em + 3vmin);
        text-shadow: 2px 2px 3px rgba(#000, 0.2);
    }
    & h2 {
        width: 80%;
        max-width: 90vw;
        line-height: 1em;
        color: $zuta;
        margin: 0.1em auto;
        font-size: 2em;
        font-size: calc(0.75em + 2.5vmin);
        text-shadow: 2px 2px 3px rgba(#000, 0.2);
    }
    & h2.fancy {
        width: 80%;
        max-width: 90vw;
        font-size: 2em;
        font-size: calc(0.75em + 1.5vmin);
        text-align: center;
        @include breakpoint($large) {
            padding-left: 150px;
        }
    }
}

// SEARCH FORMA
.searchbar {
    position: relative;
    background-color: $plava;
    overflow: hidden;
}

.searchcall {
    text-align: center;
    font-size: 1.2em;
    font-weight: 300;
    margin: 0.5em auto;
    padding: 5px;
    color: tint($plava, 60);
    transition: all 0.2s linear;
    &:after {
        content: '';
        margin-left: 15px;
        font-size: 0.5em;
        @include sprite(povecalo1, all);
        margin-bottom: -3px;
    }
    &:hover {
        cursor: pointer;
        color: tint($plava, 90);
    }
}

.searchizlet {
    max-height: 0;
    // position: absolute;
    // z-index: 99;
    top: 0;
    // transform: rotateX(90deg);
    // height: 0;
    transform: scaleY(0.5);
    transform: scaleX(0);
    transition: all 0.2s linear;
    // overflow: hidden;
    // margin: 1em auto;
    box-sizing: border-box;
    padding: 0em;
    overflow: hidden;
    // width: 20vw;
    // max-width: 1000px;
    // flex: 1 1 600px;
    line-height: 1.2em;
    // max-width: 600px;
    // background-color: rgba($tplava, 0.5);
    color: #fff;
}

.visiblesearch {
    padding: 2em 1em;
    // top: 0;
    // transform: rotateX(0deg);
    // height: auto;
    transform: scaleY(1);
    transform: scaleX(1);
    max-height: 700px;
    @include breakpoint($large) {
        max-height: 200px;
    }
}

.searchform {
    margin: 0;
    padding: 0;
    // padding: 10px 20px;
    display: flex;
    align-items: center;
    // justify-content: space-between;
    justify-content: space-around;
    flex-wrap: nowrap;
    flex-direction: column;
    @include breakpoint($large) {
        justify-content: center;
        flex-direction: row;
    }
}

.searchform li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    list-style-image: none;
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: 10px;
    @include breakpoint($large) {
        width: auto;
        align-self: stretch;
        flex-direction: column;
        margin: 0 10px;
        flex: 0 1 auto;
    }
}

.searchform .libuton {
    align-self: flex-end;
}

.searchform li label,
.searchform li p {
    width: 50%;
    align-self: center;
    margin-bottom: 0px;
    margin-right: 10px;
    @include breakpoint($large) {
        align-self: flex-start;
        width: auto;
        margin-bottom: 10px;
    }
    flex: 0 0 auto;
    text-align: right;
    font-weight: normal;
    color: #fff;
    font-size: 0.9em;
    text-transform: uppercase;
}

.searchform input,
.searchform button,
.searchform select {
    order: 2;
    flex: 0 0 auto;
    text-align: left;
    align-self: center;
    // align-self: flex-start;
    @include breakpoint($large) {
        align-self: flex-start;
    }
}

.searchform select {
    padding-right: 30px;
}

.searchform li.ds {
    width: auto;
    align-self: stretch;
    flex-direction: column;
    flex: 0 1 auto;
    margin: 0 10px 30px 10px;
    @include breakpoint($large) {
        margin: 0 10px;
    }
    & label {
        align-self: center;
        @include breakpoint($large) {
            align-self: flex-start;
        }
        width: auto;
        margin-bottom: 10px;
    }
    & select {
        // @include breakpoint($tablet) {
        // width: 100%;
        // }
        align-self: center;
        @include breakpoint($large) {
            align-self: flex-start;
        }
    }
}

.ui-datepicker {
    background-color: $zuta;
    padding: 10px;
    & .ui-datepicker-prev,
    .ui-datepicker-next {
        display: none;
    }
}

#searchbuton {
    // margin-left: auto;
    padding: 7px 12px;
    border: none;
    background: $zelena;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        background: $zelena-buton;
    }
}

.searchform {
    & select {
        background: transparent;
        border: 1px dotted #fff;
        background-color: tint($plava, 70);
        font-size: 1em;
        color: $tplava;
        padding: 5px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
        background-position: 100% 50%;
        background-repeat: no-repeat;
        /* If you add too much padding here, the options won't show in IE */
    }
    & input {
        background: transparent;
        border: 1px dotted #fff;
        background-color: tint($plava, 70);
        font-size: 1em;
        color: $tplava;
        padding: 5px;
        /* If you add too much padding here, the options won't show in IE */
    }
    & input[type=checkbox] {
        -webkit-appearance: none;
        background-color: tint($plava, 70);
        border: 1px dotted #fff;
        box-shadow: none;
        margin: 2px;
        padding: 12px;
        border-radius: 2px;
        display: inline-block;
        position: relative;
        &:active,
         :checked:active {
            box-shadow: none;
        }
        &:checked {
            background-color: $zelena;
            box-shadow: none;
            color: #fff;
            padding: 14px;
            margin: 0;
        }
        // &:checked:after {
        //     content: '';
        //     font-size: 2em;
        //     color: #fff;
        //     position: absolute;
        //     top: -5px;
        //     left: -5px;
        //     @include sprite(sn-instagram,
        //     all);
        // }
        &:checked:after {
            position: absolute;
            top: -1px;
            left: -1px;
            content: '';
            font-size: 2em;
            @include sprite(check,
            all);
        }
    }
}


/*
 * 	Default theme - Owl Carousel CSS File
 */

$color-base: #869791 !default;
$color-white: #FFF !default;
$color-gray: #D6D6D6 !default;
//nav
$nav-color: $color-white !default;
$nav-color-hover: $color-white !default;
$nav-font-size: 14px !default;
$nav-rounded: 1px !default;
$nav-margin: 5px !default;
$nav-padding: 4px 7px !default;
$nav-background: tint($plava,
70);
$nav-background-hover: $plava;
$nav-disabled-opacity: 0.5 !default;
//dots
$dot-width: 20px !default;
$dot-height: 10px !default;
$dot-rounded: 0px !default;
$dot-margin: 1px 1px !default;
$dot-background: tint($plava,
70);
$dot-background-active: $plava;
@import 'owltheme';
.owl-theme .owl-nav {
    margin-top: 0;
}

// .owl-nav {
//     font-size: 1.4em;
// }
// .owl-dots {
//     font-size: 1.4em;
// }
// .owl-next,
// .owl-prev {
//     background-color: tint($plava, 70);
// }
.headerpromo .promolink {
    display: none;
    @include breakpoint($large) {
        display: flex;
        justify-content: center;
    }
}

.promolink {
    // display: none;
    // @include breakpoint($large) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    overflow-x: hidden;
    // }
    // background-color: tint($plava, 70);
    margin: 2em auto;
    & a,
    p {
        text-transform: uppercase;
        letter-spacing: .05em;
        border-radius: 2px;
        font-size: 1em;
        flex: 0 1 auto;
        align-self: center;
        padding: 19px 20px;
        border: 1px solid white;
        color: #fff;
        background-color: $plava;
        background-color: rgba($plava, 0.7);
        transform: all 0.2s linear;
    }
    & p {
        padding: 14px 20px;
        // margin: 0 10px 0 0;
        margin: 0 10px;
        text-align: center;
    }
    & a:not(:last-child) {
        // border-right: 1px dotted $plava;
        margin-right: 10px;
        margin-left: 10px;
    }
    & a:hover {
        background-color: $zelena;
        background-color: rgba($zelena, 1);
        color: #fff;
    }
    & select {
        margin: 0 15px;
        max-width: 85%;
        box-sizing: border-box;
        background: transparent;
        border: none;
        background-color: tint($plava, 90);
        font-size: 1em;
        color: tint($plava, 60);
        padding: 5px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
        background-position: 100% 50%;
        background-repeat: no-repeat;
        /* If you add too much padding here, the options won't show in IE */
        &:focus {
            color: $tplava;
        }
    }
}

// call to action buton
.cta {
    text-align: center;
    display: inline-block;
    margin: 1em auto;
    &:first-child {
        margin: 0 auto 1em;
    }
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: 2px;
    font-size: 1em;
    flex: 0 1 auto;
    padding: 14px 20px;
    border: 1px solid white;
    color: #fff;
    background-color: $plava;
    background-color: rgba($plava,
    0.7);
    transform: all 0.2s linear;
    &:hover {
        background-color: $zelena;
        background-color: rgba($zelena, 1);
        color: #fff;
    }
    & span {
        display: block;
        font-size: 0.7em;
    }
}

.menudesktop {
    display: none;
    @include breakpoint($large) {
        display: flex;
        justify-content: center;
    }
    // background-color: tint($plava, 70);
    margin: 2em auto;
    & a {
        font-size: 1em;
        flex: 0 1 auto;
        align-self: center;
        padding: 20px;
        color: $plava;
        transform: all 0.2s linear;
    }
    & a:not(:last-child) {
        border-right: 1px dotted $plava;
    }
    & a:hover {
        background-color: $plava;
        color: #fff;
    }
}

// @media screen and (max-width: 992px) {
//     #izleti-carousel .owl-item.cloned {
//         display: none !important;
//     }
//     #izleti-carousel .owl-stage {
//         transform: none !important;
//         transition: none !important;
//         width: auto !important;
//     }
//     #izleti-carousel .owl-item {
//         width: auto !important;
//     }
// }
// why book with use
.whyus {
    background: #eee;
    text-align: center;
    padding: 1.8em 1em;
    & h3 {
        font-size: 1.7em;
        color: $plava;
        margin: 0 0 0.5em;
        padding: 0;
    }
    & h3.harmoplus {
        font-size: 1.5em;
        font-size: calc(1em + 0.5vmin);
        color: $plava;
        text-transform: uppercase;
        margin: 0;
        padding: 0;
    }
    & h4 {
        font-size: 1em;
        color: $siva;
        margin: 0;
        padding: 0 0.5em 0.5em;
    }
    & .nika h4 {
        font-size: 1em;
        font-size: calc(1em + 0.5vmin);
        color: $siva;
        margin: 0;
        padding: 0 0.5em 0.5em;
    }
    & ul {
        max-width: 1200px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        padding: 0.5em;
        list-style: none;
        & li {
            flex: 1 1 90%;
            list-style-type: none;
            @include breakpoint ($large) {
                flex: 1 1 15%;
            }
            // background-color: #fff;
        }
        & li:not(:last-child) {
            padding-bottom: 1em;
            border-bottom: 1px solid #ccc;
            margin-bottom: 0.5em;
            @include breakpoint ($large) {
                padding: 0;
                border: none;
                margin: 0;
                padding-right: 1em;
                border-right: 1px solid #aaa;
                margin-right: 1em;
            }
        }
    }
}

// TRIP ADVISOR REVIEWS I WIDGET
.triper {
    margin: 1em auto;
    text-align: center;
    max-width: 960px;
}

#CDSWIDSSP {
    width: 100% !important;
}

.widSSPData {
    border: none !important;
}

// SINGLE PAGE style
.promoporuka {
    // font-size: 1.8em;
    font-weight: normal;
}

.bookstatic {
    z-index: 900;
    width: 100%;
    width: 100vw;
    position: fixed;
    bottom: 0;
    display: block;
    text-align: center;
    padding: 10px;
    background-color: $zelena;
    color: #fff;
    cursor: pointer;
    &:hover {
        background-color: $tplava;
        color: #fff;
    }
}

.single .content {
    margin: 0 auto;
    // max-width: 1200px;
    & img {
        max-width: 100%;
        height: auto;
    }
}

.content-stranica {
    background-color: #fff;
    width: 90%;
    max-width: 1200px;
    margin: 2em auto;
}

.bookinfo {
    text-align: center;
    margin: 0 auto;
    // padding: 20px;
    background-color: #efefef;
}

.mainbookinfo {
    display: flex;
    flex-wrap: wrap;
}

.bookingdates {
    box-sizing: border-box;
    // width: 100%;
    flex: 1 1 479px;
    @include breakpoint($large) {
        // flex: 1 1 auto;
        // max-width: 40%;
    }
    text-align: center;
    margin: 0 auto;
    padding: 0.5em 1em;
    // max-width: 1200px;
    background-color: $ssiva;
    & h3 {
        font-size: 2em;
        font-size: calc(0.75em + 1.65vmin);
        color: $tplava;
    }
}

.basicdata {
    box-sizing: border-box;
    // padding: 20px 5vw;
    align-self: stretch;
    flex: 1 1 479px;
    // width: 100%;
    // @include breakpoint($large) {
    //     // max-width: 40%;
    // }
    background-color: $plava;
    text-align: center;
    padding: 0.5em 10px;
    & h3 {
        font-size: 2em;
        font-size: calc(0.75em + 1.65vmin);
        color: #fff;
    }
    & table {
        margin: 0 auto 2em;
        max-width: 900px;
        line-height: 1em;
        @include breakpoint($large) {
            line-height: 1.2em;
        }
        & td {
            text-align: left;
            padding: 0.3em 0.8em 0.3em 0;
            color: #fff;
            @include breakpoint($large) {
                padding: 1em 0.8em 1em 0;
            }
        }
        & td.c1 {
            text-transform: uppercase;
            font-size: 0.8em;
            // transform: rotate(-90deg);
        }
        & td.c2 {
            border-top: 1px dotted #fff;
            font-size: 0.9em;
        }
        & table {
            width: 100%;
            // & tr {
            //     border-top: 1px solid $mplava;
            // }
            & td {
                text-align: left;
                padding: 0.1em 0em 0.1em 0;
                @include breakpoint($large) {
                    padding: 0.2em 0em 0.2em 0;
                }
                color: #fff;
            }
            & td.c3 {
                // width: 60%;
                vertical-align: top;
            }
            & td.c4 {
                width: 25%;
                vertical-align: top;
            }
        }
    }
}

.content h2,
.content h3 {
    // text-align: center;
    & span {
        color: $tplava;
        // font-weight: normal;
    }
}

.bookinglist {
    padding: 0 10px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: center;
    justify-content: center;
    & li {
        text-align: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: center;
        background-color: $zelena;
        flex: 1 1 40%;
        margin: 5px;
        // min-height: 20vh;
        border: 2px solid #fff;
        // display: flex;
        // flex-direction: center;
        &:hover {
            background-color: $plava;
        }
        & a {
            display: block;
            color: #fff;
            letter-spacing: .09em;
            text-transform: uppercase;
            // align-self: center;
            padding: 0.5em 0.5em 1.5em;
            & span {
                display: block;
                background-color: $tplava;
                // color: $zelena;
                padding: 4px;
                font-size: 0.8em;
                margin-bottom: 1em;
            }
        }
        @include breakpoint($tablet) {
            flex: 0 1 20%;
            // & li:not(:last-child) {
            //     margin-right: 20px;
            // }
        }
    }
}

// prikaz bookinjgdatuma na prikazu svih izleta
.promoizlet .bookinglist {
    background-color: #eee;
    padding: 10px 10px;
    margin: 0;
    display: flex;
    flex-wrap: nowrap;
    flex-direction: center;
    justify-content: center;
    & li {
        font-size: 0.7em;
        text-align: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: center;
        background-color: $zelena;
        // flex: 1 1 20%;
        flex: 1 1 20%;
        // max-width: 120px; // da ne budu presiroki kad je samo jedan izlet po retku
        margin: 2px;
        border: none;
        &:hover {
            background-color: $plava;
        }
        & a {
            display: block;
            color: #fff;
            letter-spacing: .09em;
            text-transform: uppercase;
            // padding: 0.5em 0.5em 1.5em;
            padding: 0 0 1em 0;
            & span {
                font-size: 0.8em;
                display: block;
                background-color: $tplava;
                padding: 2px 3px;
                margin-bottom: 1em;
            }
        }
        @include breakpoint($tablet) {
            flex: 1 1 20%;
        }
    }
}

// napomena izleti
.nota {
    font-size: 1em;
    color: $plava;
    width: 90%;
    max-width: 600px;
    border-bottom: 1px dotted $plava;
    text-align: center;
    margin: 0 auto 1em;
    padding: 1em 0.5em;
    @include breakpoint($tablet) {
        border-top: 1px dotted $plava;
        margin: 2em auto;
        padding: 2em 0.5em;
    }
}

.bookinglistmore {
    font-size: 0.9em;
    padding: 0 10px;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: center;
    justify-content: center;
    background-color: #fff;
    &:before {
        content: '';
        width: 100%;
        height: 20px;
    }
    & li {
        text-align: center;
        padding: 0;
        margin: 0;
        list-style-type: none;
        text-align: center;
        background-color: $zelena;
        // flex: 0 0 auto;
        flex: 1 1 auto;
        margin: 10px;
        // min-height: 20vh;
        border: 2px solid #fff;
        // display: flex;
        // flex-direction: center;
        & a {
            display: block;
            color: #fff;
            // align-self: center;
            padding: 0.5em 0.5em;
            &:hover {
                background-color: $plava;
            }
        }
        @include breakpoint($tablet) {
            // flex: 0 1 2%;
            flex: 1 1 auto;
            max-width: 200px;
            // & li:not(:last-child) {
            //     margin-right: 20px;
            // }
        }
    }
    &.isDown {
        margin-top: 0;
    }
}

// .izlet-video {
//     text-align: center;
//     & iframe video {
//         object-fit: cover;
//         // width: 100%;
//         // height: auto;
//         // max-height: 100%;
//     }
// }
.izlet-video-wrapper {
    max-width: 960px;
    margin: 1em auto;
    & p {
        font-size: 0.7em;
        margin: 5px 10px;
        padding: 0;
        color: $ssiva;
    }
}

.izlet-video {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
}

.izlet-video iframe,
.izlet-video object,
.izlet-video embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.harmodole {
    font-size:0.85em;
    background-color: $ssiva;
    display: inline-block;
    padding: 5px 20px 10px;
    text-align: center;
    transform: all 0.2s ease-in-out;
    margin-bottom: 0;
    & span {
        // display: inline-block;
    }
    & span:after {
        content: '';
        @include sprite(st-dole-plava, all);
        font-size: 0.8em;
        // margin-left: 10px;
        // margin-top: 3px;
        margin: 0 auto;
        display: block;
    }
    &:hover {
        cursor: pointer;
        background-color: #fff;
    }
    &.isOpen {
        background-color: #fff;
        &:hover {
            color: $siva;
        }
        & span:after {
            transform: rotate(180deg);
        }
    }
}

.nika .harmodole {
    background-color: #fff;
    &:hover {
        background-color: $zuta;
    }
}

.harmo {
    // display: inline-block;
    position: relative;
    text-align: center;
}

.nika {
    max-height: 0;
    overflow: hidden;
    margin: 0;
}

.isDown {
    max-height: 100%;
    @include breakpoint($large) {
        max-height: 15000px;
    }
    margin-top: 1em;
}

.izletintro,
.highlights {
    max-width: 1200px;
    padding: 0 1em;
    margin: 2em auto;
}

.izletintro {
    & h2 {
        // font-size: 2em;
        // font-size: calc(0.75em + 3vmin);
        text-align: center;
    }
}

.highlights {
    & h2 {
        // font-size: 2em;
        // font-size: calc(0.75em + 2.5vmin);
        text-align: center;
        color: $plava;
    }
    & li {
        margin-bottom: 0.8em;
    }
}

.harmoplus {
    font-size: 1.5em;
    font-size: calc(1em + 0.5vmin);
    &:hover {
        cursor: pointer;
    }
    &:after {
        margin: 10px auto 0;
        font-size: 1em;
        display: block;
        // position: absolute;
        // top: -25%;
        // right: 20px;
        content: '';
        @include sprite(plus,
        all);
    }
    &.isOpen:after {
        @include sprite(minus,
        bg);
    }
}

h3.harmoplus {
    font-size: 1.5em;
    font-size: calc(1em + 0.5vmin);
}

h1.harmoplus {
    font-size: 2em;
    font-size: calc(1.5em + 0.5vmin);
    &:after {
        font-size: 0.75em;
    }
}

.includedinprice,
.izletdetail,
.sadrzaj {
    padding: 1em 1em;
    & .nika {
        margin: 0 auto;
        max-width: 1200px;
    }
    background-color: #efefef;
}

.sadrzajuski {
    padding: 1em 2em 2em;
    margin: 0 auto;
    max-width: 1200px;
    background-color: #fff;
}

.gal {
    margin-bottom: 10px;
    width: 100%;
}

.wheretobuy .content {
    background: #fff;
    padding-top: 3em;
    width: 100%;
    margin: 0px auto;
    @include breakpoint($tablet) {
        width: 90%;
        max-width: 1200px;
    }
    & .header {
        box-sizing: border-box;
        padding: 20px 20px 40px;
        & h1 {
            font-size: 2em;
            font-size: calc(0.75em + 3vmin);
            font-weight: normal;
        }
    }
}

.where-content {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
}

.where-general {
    box-sizing: border-box;
    padding: 0 20px 20px;
    flex: 1 1 360px;
    // max-width: 680px;
}

.where-local {
    box-sizing: border-box;
    padding: 20px 20px 40px;
    background: tint($plava, 90);
    background: linear-gradient(180deg, #fff 1%, tint($plava, 90) 60%, tint($plava, 80) 99%);
    flex: 1 1 120px;
    & h3 {
        margin: 0;
        font-weight: normal;
        text-transform: uppercase;
    }
    & .harmo {
        text-align: left;
        padding-top: 8px;
        &:not(:first-of-type) {
            // padding-top: 8px;
            margin-top: 10px;
            border-top: 1px dotted $plava;
        }
    }
    // max-width: 400px;
    & .harmoplus:after {
        opacity: 0.4;
        font-size: 0.6em;
        display: inline-block;
        position: absolute;
        // top: -0.1em;
        top:0.5em;
        right: 20px;
        margin:0;
        margin-left: 10px;
    }
    & .harmoplus:hover:after {
        opacity: 1;
    }
    & div {
        display: flex;
        flex-wrap: wrap;
        & p {
            flex: 1 1 200px;
        }
    }
}

// single  page corporate profile
.raster {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    list-style-type: none;
    list-style-image: none;
    padding: 0;
    & li {
        list-style-type: none;
        list-style-image: none;
        margin: 10px;
        box-sizing: border-box;
        padding: 20px;
        border: 1px dotted $plava;
        flex: 1 1 280px;
    }
}

.djelatnici {
    & strong {
        color: $plava;
        font-size: 1.3em;
        font-weight: normal;
    }
}

// PRIKAZ IZLETA U PROMO TRACI 
.groupdeals {
    background-color: #f0f0f0;
    background: linear-gradient(0deg, tint($plava, 80) 1%, #f0f0f0 50%, tint($plava, 60) 99%);
    & .content {
        // background-color: #fff;
        // padding: 20px;
        width: 100%;
        margin: 20px auto;
        @include breakpoint($tablet) {
            width: 90%;
            max-width: 1200px;
        }
    }
    & .content h2 {
        font-weight: normal;
        border-bottom: 1px dotted $plava;
        // font-size: 2.2em;
        // margin-top: 2em;
        // color: $tplava;
    }
    & .blogarticle {
        & h3 {
            margin: 10px 0 0 0;
            font-weight: normal;
            font-size: 1.4em;
            color: $plava;
            @include breakpoint($tablet) {
                text-align: left;
            }
        }
        & h2 {
            @include breakpoint($tablet) {
                text-align: left;
            }
        }
        & p {
            @include breakpoint($tablet) {
                text-align: left;
            }
            color: $siva;
        }
    }
}

.content-intro {
    max-width: 90%;
    margin: 0 auto;
}

// kontakt us link
.contactus {
    display: inline-block;
    margin: 1em auto;
    padding: 8px 15px;
    background-color: $mplava;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    &:hover {
        background-color: $tplava;
        color: $zuta;
    }
}

.odvoji {
    margin: 150px auto 3.5em;
}

// Akcija

.akcija {
    box-sizing: border-box;
    // width: 90%;
    margin: -30px auto 15px;
    max-width: 90%;
    display: block;
    background-color: #fff;
    // border: 1px solid $zuta;
    border-top: 16px solid $zuta;
    border-bottom: 16px solid $tplava;
    // border-top-color: $zuta;
    border-left-color: $tplava;
    border-right-color: $tplava;
    // border-bottom-color: $zuta;
    padding: 15px;
    text-decoration:none;
    text-align: center;
    transition: all 0.3s linear;
    // box-shadow: 0px 0px 20px #aaa;
    // border-bottom-right-radius: 50px;
    // border-top-left-radius: 50px;
    @include breakpoint($tablet) {
        margin: -80px auto 20px;
        padding: 30px 20px;
        max-width:600px;
        // border: 18px solid #efefef;
    }
    &:hover {
        border-color: $plava; 
        // border-top-color: $zelena;
        // border-left-color: $zelena;
    }
    & a, a:link, a:visited {
        color: $tplava;
        &:hover {
            & h2 {
                color: $plava;
            } 
        }
    }
    & h3 {
        font-size: 1.1em;
        text-align:center;
        margin:0 auto;
        line-height: 1.2em;
        font-weight: 400;
    }
    & h2 {
        font-weight: 800;
        font-size: 1.1em;
        margin:0 auto;
        line-height: 1.2em;
        text-transform: uppercase;
    }
    & p {
        margin: 5px auto;

    }
}


// Accomodation partners

.accomodation-partners {
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    @include breakpoint($tablet) {
        justify-content: space-between;
    }
    align-items: stretch;
}

.accomodation-partner {
    position:relative;
    flex: 1 1 98%;
    max-width:600px;
    // background-color: $ssiva;
    margin-bottom:20px;
    border-bottom:5px solid $plava;
    background-color: #eee;
    & .acc-partner-opis {
        padding-top:10px;
        padding-bottom:10px;
        padding:10px;
        
    }
    & h2,h3,p {
        margin:0.5em 0;

    }
    & h2 {
        // color: $plava;
        font-size:1.4em;
        font-size:1em;
        text-transform: uppercase;
    }
    & h3 {
        font-size:1em;
        color: $plava;
        font-weight:normal;
    }
    & a {
        display:inline-block;
        margin-top: 10px;
        padding:10px;
        background-color: $plava;
        border:2px solid #fff;
        position:absolute;
        right:10px;
        top:0;
    }
    & a, a:link, a:visited, a:active {
        color: #fff;
        
    }
    & a:hover{
        background-color: $zelena;
    } 
    & img {
        max-width:100%;
        height:auto;
    }

    @include breakpoint($tablet) {
        flex: 0 1 48%;
    }
    @include breakpoint($large) {
        // max-width:30%;
        flex: 0 1 32%;
            }
            @include breakpoint($wide) {
                // max-width:30%;
                flex: 0 1 23%;
                    }

}