.menubuton {
    text-indent: -150%;
    overflow: hidden;
    background-color: $zuta;
    border: 4px solid $zuta;
    // padding: 5px 10px 1px;
    font-size: 1.2em;
    z-index: 9999;
    position: fixed;
    top: 11px;
    right: 15px;
    // @include breakpoint($tablet) {
    //     top: 50px;
    // }
    margin: 0;
    @include sprite(but-menu, all);
    transition: font-size 0.3s linear;
    &:hover {
        background-color: #fff;
        border-color: #fff;
        cursor: pointer;
        font-size: 1.3em;
        // overflow: visible;
        // text-indent: -60px;
    }
}

.menuout .menubuton {
    @include sprite(but-menu-close, bg);
}

.content {
    position: relative;
}

.menuout .content {
    // height: 100vh;
    // position: fixed;
    overflow: hidden;
}

// Kompletni blok izbornika
.mainmenu {
    max-width: 960px;
    margin: auto;
    padding: 20px;
    // background-color: $zuta;
    // background-color: $tplava;
    // background-color: rgba($tplava, 0.9);
    color: tint($plava, 60);
    & a,
    a:visited,
    a:link {
        color: #fff;
        &:hover {
            color: $plava;
        }
    }
    // min-height: 100vh;
    position: relative;
    // position: absolute;
    // top: -1600px;
    // left: 0;
    // right: 0;
    // bottom: 0;
    // z-index: 999;
    display: flex;
    flex-flow: column;
    justify-content: center;
    flex-wrap: nowrap;
    text-align: center;
    // flex: 0 1 960px;
    transition: all 0.2s linear;
}

.mainmenuoverlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 0;
    position: fixed;
    /* Stay in place */
    z-index: 99;
    /* Sit on top */
    right: 0;
    top: 0;
    // background-color: $zuta;
    background-color: $tplava;
    background-color: rgba($tplava, 0.94);
    overflow-x: hidden;
    overflow-y: hidden;
    /* Disable horizontal scroll */
    transition: 0.4s;
    /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
    z-index: 999;
}

.visible {
    // background-color: $zuta;
    width: 100vw;
    overflow-y: auto;
    @include breakpoint($large) {
        width: 60vw;
    }
}

// Zaglavlje izbornika (logo)
.menutitle {
    margin: 1em auto;
    font-size: 0.5em;
    @include breakpoint($tablet) {
        font-size: 0.7em;
    }
    text-indent: -200%;
    overflow: hidden;
    @include sprite(croatiaexcursion-logo, all);
}

// Izbornik jezika
.menujezik {
    & ul,
    li {
        list-style-type: none !important;
        list-style-image: none !important;
        margin: 0;
        padding: 0;
    }
    & ul {
        padding: 20px;
        text-align: center;
        display: flex;
        justify-content: center;
        & li {
            // display: inline-block;
        }
    }
    & a {
        box-sizing: border-box;
        display: block;
    }
    // @include breakpoint($wide) {
    //     // position:relative;
    //     // background-color:transparent;
    //     // @include span(1 of 12 last);
    //     & a {
    //         // box-sizing:border-box;
    //         // display:block;
    //         background-color: $tplava;
    //         font-size: 0.75em;
    //         line-height: 1em;
    //         padding: 0.5em 0.8em;
    //         color: #fff;
    //         &.active {
    //             background-color: #555;
    //             color: #aaa;
    //         }
    //         &:hover,
    //         &:hover.active {
    //             background-color: $zelena;
    //             color: #fff;
    //         }
    //     }
    //     & ul {
    //         padding: 0;
    //         position: absolute;
    //         transition: height 0.2s linear;
    //         height: 1.5em; // visina a fonta + padding 0.75 + 0.5 + 0.5
    //         overflow: hidden;
    //         &:hover {
    //             height: 4.5em; // prikazi i ostale jezike
    //         }
    //         & li {
    //             display: block;
    //         }
    //     }
    // }
}

.mainmenu .menujezik {
    & a {
        padding: 0.6em 0.8em;
        // color: $tplava;
        &.active {
            border-bottom: 1px dotted $zuta;
            // color: $zelena;
        }
        &:hover,
        &:hover.active {
            background-color: #fff;
            // color: $zelena;
        }
    }
    @include breakpoint($large) {
        display: none;
    }
}

.partnerlogin {
    align-self: center;
    margin: 1.5em;
    padding: 0.5em 1em;
    border: 1px dotted #fff;
}

// Izdvojeni izlet u izborniku
.menuizlet {
    // border-style: dotted;
    // border-color: $tplava;
    // border-width: 1px 0 1px 0;
    position: relative;
    // omjer izleta u izborniku 8:6
    width: 90vw;
    height: 60vw;
    max-height: 400px;
    max-width: 600px;
    margin: 1em auto;
    & .izletipasica h2 {
        text-align: center;
        font-size: 1.2em;
        font-weight: normal;
    }
}

// izbornik - popis svih izleta
.menuizleti {
    display: none;
    @include breakpoint($large) {
        & a {
            display: block;
            // color: $tplava;
            font-size: 1em;
            padding: 0.5em;
            line-height: 1.2em;
            font-weight: 400;
            transform: all 0.2s linear;
            // &:hover {
            //     color: $plava;
            // }
        }
        padding: 0.2em 0;
        margin: 0 auto;
        max-width: 1000px;
        display: flex;
        flex-flow: row wrap;
    }
}

.submenuizleti {
    background-color: $plava;
    // margin: 10px;
    box-sizing: content-box;
    margin: 2px;
    flex: 1 1 100%;
    & a:last-child {
        margin-bottom: 10px;
    }
    & a,
    a:visited,
    a:link {
        &:hover {
            color: $tplava;
        }
    }
    @include breakpoint($tablet) {
        // justify-content: center;
        flex: 1 1 30%;
    }
}

.menuglavnestavke {
    & a {
        display: block;
        // color: $tplava;
        font-size: 1.2em;
        padding: 0.5em 0;
        font-weight: normal;
        transform: all 0.2s linear;
        // &:hover {
        //     color: $plava;
        // }
    }
    padding: 0.5em 1.5em;
    margin:0 auto;
    border-bottom: 1px dotted tint($tplava, 70);
    @include breakpoint($large) {
        border: none;
    }
}

.menustavke {
    & a {
        display: block;
        // color: $tplava;
        // font-size: 1.1em;
        padding: 0.5em 0;
        font-weight: normal;
        transform: all 0.2s linear;
        // &:hover {
        //     color: $plava;
        // }
    }
    padding: 0.2em 1.5em;
    margin:0 auto 1.5em;
}

// linkovi na drustvene mreze u izborniku (ikone definirane u _ikone.scss)
.menusocialnetwork {
    & a,
    a:visited {
        text-indent: 9999px;
        font-size: 1.4em;
        margin: 2px;
        border: 4px solid $zuta;
        display: inline-block;
        overflow: hidden;
        &:hover {
            background-color: #fff;
            border: 4px solid #fff;
        }
    }
}

.mainmenu .menusocialnetwork {
    & a,
    a:visited {
        background-color: #fff;
        border: 4px solid #fff;
        &:hover {
            background-color: $plava;
            border: 4px solid $plava;
        }
    }
}

// kontakt telefon i mail u izborniku
.menukontakti {
    font-size: 0.9em;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    & a,
    p {
        margin-left: 0.5em;
    }
}

// .contacts {
//     & a {
//         margin: 0 5px;
//     }
// }
.contact-skype {
    text-indent: -200%;
    @include sprite(contact-skype, all);
    overflow: hidden;
}

.contact-viber {
    text-indent: -200%;
    overflow: hidden;
    @include sprite(contact-viber, all);
}

.contact-whatsup {
    text-indent: -200%;
    overflow: hidden;
    @include sprite(contact-whatsup, all);
}

.mailbook {
    display: none;
}

.login .mailbook {
    display: block;
}

.login .mailinfo {
    display: none;
}

.businessmenu {
    & a:not(:last-child) {
        padding-right: 1em;
        margin-right: 1em;
        border-right: 1px dotted $plava;
    }
    font-size: 0.9em;
    margin-bottom:1em;
}