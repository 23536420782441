.bookingstep {
    & .zaglavlje {
        padding-bottom: 8px;
        & .userbar {
            margin-bottom: 0;
        }
    }
    .content {
        background-color: #f0f0f0;
        background: linear-gradient(0deg, #f0f0f0, #f0f0f0 60%, tint($plava, 60) 99%);
    }
}

.bookingstep-content {
    background-color: #fff;
    background-color: #f0f0f0;
    max-width: 960px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 2em;
    & .header-booking {
        background-color: #f2f2f2;
        background-color: $plava;
        padding: 10px 20px;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include breakpoint($tablet) {
            flex-direction: row;
            align-items: center;
        }
        justify-content: space-between;
    }
    & .booking-note {
        background-color: #f2f2f2;
        padding: 10px 20px;
    }
}

.bizletname {
    font-weight: normal;
}

.bizlet {
    color: $tplava;
    line-height: 1.2em;
    margin-top: 0;
    background-color: #f2f2f2;
    padding: 10px 20px;
    & span {
        font-weight: normal;
        color: $plava;
    }
}

.bdateall {
    display: inline-block;
    // background-color: $zuta;
    border: 1px dotted $plava;
    // border-left: 20px solid $plava;
    & .bdate {
        margin: 0;
        padding: 5px 10px;
        font-weight: bold;
        border-bottom: 1px dotted $plava;
    }
    & .bday {
        margin: 0;
        padding: 5px 10px;
        font-weight: 0.9em;
        text-transform: uppercase;
    }
}

.bstep {
    // max-width: 960px;
    // margin: 0 auto;
    text-align: right;
    background-color: #efefef;
    & h2 {
        // margin: 2em 2em 0 0;
        display: inline-block;
        padding: 10px 20px;
        margin: 1em 0 0;
        background-color: $plava;
        color: #fff;
        font-size: 2em;
        font-weight: normal;
    }
}

.bstep1 {
    & .bstep h2,
    .header-booking {
        background-color: $plava;
        color: #fff;
    }
    & .header-booking {
        background: linear-gradient(45deg, $tplava 0%, $plava 80%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    & .bizletname {
        color: #fff;
    }
    & .bdateall {
        border: 1px dotted #fff;
        & .bdate {
            border-bottom: 1px dotted #fff;
        }
    }
}

.bstep2 {
    & .bstep h2,
    .header-booking,
    .booking-note {
        background-color: $tplava;
        color: #fff;
    }
    & .header-booking,
    .booking-note {
        background: linear-gradient(45deg, $plava 0%, $tplava 80%);
        /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    }
    & .bizletname {
        color: #fff;
    }
    & .bdateall {
        border: 1px dotted #fff;
        & .bdate {
            border-bottom: 1px dotted #fff;
        }
    }
}

.bookingform {
    margin: 1em 0;
    padding: 0;
    list-style-type: none;
}

.bookingform li {
    counter-increment: step-counter;
    margin-bottom: 2em;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    @include breakpoint($tablet) {
        flex-direction: row;
    }
    &:not(:last-child) {
        background-color: #fff;
        padding: 2em;
        border: 1px dotted $plava;
    }
}

.bookingform li:not(:last-child):before {
    content: counter(step-counter);
    background-color: $plava;
    color: #fff;
    font-weight: bold;
    padding: 1em;
    width: 1em;
    height: 1em;
    // display: inline-block;
    text-align: center;
    border-radius: 1.5em;
    line-height: 1em;
}

.bookingform li.next {
    // border-top: 1px dotted $ssiva;
    padding-top: 1em;
    display: block;
    text-align: right;
}

.bookingform li.promocode {
    background-color: $zuta;
}

.bfitem {
    // display: flex;
    // flex-direction: column;
    @include breakpoint($tablet) {
        padding-left: 3em;
    }
    width: 100%;
    & h3 {
        text-align: left;
        margin-top: 0;
    }
}

.bookingopcije {
    & label {
        display: block;
        margin-bottom: 5px;
    }
}

.formbook {
    & label.required:after {
        content: '*';
        font-weight: bold;
        color: $plava;
    }
    & label.required:hover:after {
        content: ' REQUIRED';
        font-size: 0.8em;
        font-weight: normal;
    }
}

.bookingrow {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    @include breakpoint($tablet) {
        flex-direction: row;
        flex-wrap: wrap;
        width: 90%;
        margin-bottom: 5px;
    }
    & label {
        cursor: pointer;
        width: 100%;
        @include breakpoint($tablet) {
            width: 50%;
        }
        cursor: pointer;
    }
    & input,
    select {
        width: 50px;
        // margin-right: 20px;
        margin-left: auto;
    }
    & p {
        margin: 0 0 0 1em;
        text-align: left;
        width: 30%;
    }
}

#nextbuton {
    // margin-left: auto;
    padding: 7px 12px;
    border: none;
    background: $zelena;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        background: $zelena-buton;
    }
}

#resetbuton {
    margin-left: 20px;
    padding: 7px 12px;
    border: none;
    background: $crvena;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: .05em;
    border-radius: 2px;
    &:hover {
        cursor: pointer;
        background: $tplava;
    }
}

.formbook {
    & select {
        box-sizing: border-box;
        background: transparent;
        border: none;
        background-color: tint($plava, 90);
        font-size: 1em;
        color: tint($plava, 60);
        padding: 5px;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
        background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='#444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
        background-position: 100% 50%;
        background-repeat: no-repeat;
        /* If you add too much padding here, the options won't show in IE */
        &:focus {
            color: $tplava;
        }
        &:invalid {
            border-right: 20px solid $crvena;
            // background-color: $crvena;
            // color: tint($crvena, 60);
        }
        &:valid {
            border-right: 20px solid $zelena;
            color: $tplava;
        }
        &:optional {
            border-right: 20px solid tint($plava, 90);
        }
        &:optional:invalid {
            border-right: 20px solid $crvena;
            // background-color: $crvena;
            // color: tint($crvena, 60);
        }
        &.error {
            border-right: 20px solid $crvena;
        }
    }
    // & input,
    & textarea {
        box-sizing: border-box;
        background: transparent;
        // border: 1px dotted #fff;
        border: none;
        background-color: tint($plava, 90);
        font-size: 1em;
        // color: tint($plava, 60);
        color: $tplava;
        padding: 5px;
        /* If you add too much padding here, the options won't show in IE */
        &:focus {
            color: $tplava;
        }
        &:invalid,
         :invalid::placeholder {
            border-right: 20px solid $crvena;
            // background-color: $crvena;
            // color: #fff;
        }
        // &:invalid::placeholder {
        //     border-right: 20px solid $crvena;
        //     // background-color: $crvena;
        //     // color: tint($crvena, 60);
        // }
        &:valid {
            border-right: 20px solid $zelena;
        }
        &:optional {
            border-right: 20px solid tint($plava, 90);
        }
        &:optional:invalid {
            border-right: 20px solid $crvena;
        }
        &.error {
            border-right: 20px solid $crvena;
        }
    }
    & ::placeholder {
        // opacity: 0.5;
        color: tint($plava, 60);
    }
    // :required::placeholder {
    //     color: blue;
    // }
    // & :required:focus::placeholder {
    //     color: $tplava;
    // }
    & .upute {
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        text-align: center;
        width: 100%;
        flex: 0 0 100%;
        max-height: 0;
        overflow: hidden;
        background-color: $crvena;
        padding: 0px;
        margin: 0 auto 0px;
    }
    & .form-error {
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        text-align: center;
        width: 100%;
        flex: 0 0 100%;
        font-size: 0.8em;
        color: $crvena;
        // max-height: 0;
        // overflow: hidden;
        // background-color: $crvena;
        padding: 2px;
        margin: 8px auto 20px;
        align-self: center;
    }
    & :focus:invalid {
        &+.upute {
            font-size: 0.8em;
            display: block;
            padding: 2px;
            align-self: center;
            color: #fff;
            max-height: 300px;
            margin: 8px auto 20px;
        }
    }
    & :focus+.form-error {
        box-sizing: border-box;
        transition: all 0.2s ease-in-out;
        text-align: center;
        width: 100%;
        flex: 0 0 100%;
        font-size: 0.8em;
        color: #fff;
        // max-height: 0;
        // overflow: hidden;
        background-color: $crvena;
        padding: 2px;
        margin: 8px auto 20px;
        align-self: center;
    }
    & .uputemail {
        font-size: 0.8em;
        box-sizing: border-box;
        text-align: center;
        width: 100%;
        flex: 0 0 100%;
        background-color: $crvena;
        color: #fff;
        padding: 5px;
        margin: 0 auto 0px;
    }
    // & :required {
    //     border-right-width: 40px;
    // }
    & input[type=text],
    input[type=email],
    input[type=tel],
    input[type=password] {
        box-sizing: border-box;
        background: transparent;
        // border: 1px dotted #fff;
        border: none;
        background-color: tint($plava, 90);
        font-size: 1em;
        // color: tint($plava, 60);
        color: $tplava;
        padding: 5px;
        /* If you add too much padding here, the options won't show in IE */
        &:focus {
            color: $tplava;
        }
        &:invalid,
         :invalid::placeholder {
            border-right: 20px solid $crvena;
            // background-color: $crvena;
            // color: #fff;
        }
        // &:invalid::placeholder {
        //     border-right: 20px solid $crvena;
        //     // background-color: $crvena;
        //     // color: tint($crvena, 60);
        // }
        &:valid {
            border-right: 20px solid $zelena;
        }
        &:optional {
            border-right: 20px solid tint($plava, 90);
        }
        &:optional:invalid {
            border-right: 20px solid $crvena;
        }
        &.error {
            border-right: 20px solid $crvena;
        }
        width: 100%;
        @include breakpoint($tablet) {
            width: 45%;
        }
    }
    input[type=radio] {
        -webkit-appearance: radio;
        display: inline-block;
        // margin-top: 5px;
        width: auto;
        background-color: #99f;
    }
    & textarea {
        width: 100%;
        // @include breakpoint($tablet) {
        //     width: 90%;
        // }
    }
    & input[type=number] {
        width: 50px;
    }
    & select {
        width: 100%;
        // width: calc(100% + 10px);
        @include breakpoint($tablet) {
            width: 45%;
            // width: calc(40% + 10px);
        }
    }
}

.book-assistance {
    padding: 2em;
    margin-bottom: 0;
    & a {
        margin: 1em auto;
        font-size: 1.3em;
        padding: 5px 10px;
        border: 1px dotted $plava;
    }
    text-align: center;
}

.note-securebook {
    text-align: center;
    color: $siva;
}

.obracunp {
    display: inline-block;
    color: $siva;
    padding: 1.5em;
    border: 1px dotted $siva;
    & .subtotal {
        border-top: solid 1px $siva;
        font-weight: bold;
        display: block;
        margin: 0 0 5px;
    }
    & .total {
        border-top: solid 1px $siva;
        font-weight: bold;
        font-size: 1.1em;
        display: block;
    }
}

.confirmation {
    background-color: #fff;
    & .bstep {
        background-color: #fff;
    }
    & h3 {
        text-align: left;
    }
}

.bstavke {
    padding: 2em 0;
    margin: 0;
    list-style-type: none;
    & li {
        display: flex;
        flex-wrap: wrap;
        padding: 0;
        list-style-type: none;
        &:not(:last-child) {
            border-bottom: 1px dotted $tplava;
            margin-bottom: 1.5em;
            padding-bottom: 1.5em;
        }
        // justify-content: center;
        & p {
            -webkit-margin-before: 0;
            -webkit-margin-after: 0;
            margin: 0;
        }
        & .bstavka-label {
            color: $plava;
            box-sizing: border-box;
            // padding: 10px;
            text-transform: uppercase;
            flex: 0 0 30%;
            min-width: 150px;
            margin-right: 20px;
        }
        & .bstavka-opis {
            color: $tplava;
            // text-transform: uppercase;
            box-sizing: border-box;
            // padding: 10px;
            // background-color: #fff;
            // border-radius: 2px;
            flex: 0 1 60%;
            min-width: 300px;
        }
    }
}

// & table {
//     margin: 0 auto 2em;
//     max-width: 900px;
//     & td {
//         text-align: left;
//         padding: 0.5em 1em 0.5em 0;
//         color: #fff;
//     }
//     & td.c1 {
//         text-transform: uppercase;
//     }
//     & td.c2 {
//         border-top: 1px dotted #fff;
//     }
// }
.bnapomena {
    color: $siva;
}

.remember {
    // max-width: 600px;
    // display: inline-block;
    margin: 0 0 1em auto;
    background-color: $crvena;
    // border-bottom-left-radius: 2em;
    box-sizing: border-box;
    padding: 2em;
    color: #fff;
    & h3 {
        text-align: center;
        margin: 0;
    }
    & p {
        text-align: center;
        margin: 0 0 1em 0;
    }
    & .remember-opcije {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        & a {
            margin-top: 1em;
            padding: 0.5em 1em;
            border: dotted 1px #fff;
            color: #fff;
            &:before {
                content: '';
                font-size: 0.8em;
                @include sprite('print',
                all);
                margin-right: 5px;
            }
            &:not(:last-child) {
                margin-right: 1em;
            }
            &:hover {
                background-color: $zuta;
                color: #000;
            }
        }
    }
}

// label {
//     cursor: pointer;
//     display: block;
//     margin-bottom: .25em;
// }
// input {
//     // padding: .5em;
//     // margin-right: 1em;
//     // border: .08em solid #aaa;
//     +p {
//         display: block;
//         align-self: center;
//         color: $crvena;
//         margin: 0;
//     }
//     &:required:valid,
//     &:required:focus:valid {
//         border-color: green;
//         +p {
//             display: none;
//         }
//     }
//     &:required {
//         border-color: blue;
//         &:focus:invalid {
//             border-color: red;
//         }
//     }
// }
// CUSTOM RADIO BUTTONS
.bookingopcije {
    & input[type="radio"] {
        display: none;
    }
    & input[type="radio"]+label {
        // color: $tplava;
        display: inline;
        margin: 0;
    }
    & input[type="radio"]+label span {
        display: inline-block;
        width: 19px;
        height: 19px;
        margin: -1px 4px 0 0;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 50%;
    }
    & input[type="radio"]+label span {
        background-color: tint($plava, 90);
    }
    & input[type="radio"]:checked+label span {
        background-color: $zelena;
    }
    & input[type="radio"]+label span,
    input[type="radio"]:checked+label span {
        transition: background-color 0.3s linear;
    }
}